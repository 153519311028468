import React, { useState } from "react";
import logo from "../../public/images/R-logo.svg";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { RIFALY_USER_API, RIFALY_COOPERATE_API } from "../../constants";
import Cookie from "universal-cookie";
const cookies = new Cookie();

export default function ResetPassword() {
  var navigate = useNavigate();

  const [data, setData] = useState({
    login_id: "",
    token: "",
    password: "",
    password_confirm: "",
  });

  const handleChange = (e) => {
    const value = e.target.value;
    setData({
      ...data,
      [e.target.name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const userData = {
      email: data.email,
      token: data.code,
      password: data.password,
    };
    // Verify that the passwords match
    if (data.password !== data.password_confirm) {
      return Swal.fire("Passwords must match", "ERROR");
    } else {
      axios
        .post(`${RIFALY_USER_API}auth/corporate/reset-password`, userData)
        .then((response) => {
          if (response.status == 200) {
            Swal.fire("Success", response.data.message, "success");
            navigate("/", { replace: true });
          } else {
            Swal.fire("Failed", response.error, "error");
          }
        }).catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <div className="row loginpage">
      <div className="col-6 login_left">
        <div className="Auth-form-container">
          <div className="login_left--contents">
            <div className="row login_left--logo  my-4">
              <img src={logo} className="R-logo" alt="Rifaly logo" />
            </div>
            <div className="row login_left--title">
                Rifaly  provides an easy way to read high-quality newspaper and magazine issues from cover to cover on the go!
            </div>
          </div>
        </div>
      </div>
      <div className="col-6 login_right">
        <div className="Auth-form-container">
          <div className="login_right--form">
            <form
              className="login_right--form--contents"
              onSubmit={handleSubmit}
            >
              <h6 className="login_right--form-titlewords fw-bold my-4 pb-3">
                Reset Password ?
              </h6>

              <div className="login_right--form-title mt-2">
                <h5>
                  <span className="fw-normal mb-0">
                    Enter details and the code that was sent to your Email
                    Address
                  </span>
                </h5>
              </div>

              <div className="login_right--form-input mt-3">
                <label>Email address</label>
                <input
                  type="email"
                  id="login_id"
                  className="form-control mt-1"
                  placeholder="Enter your Email"
                  name="email"
                  defaultValue={data.email}
                  onChange={handleChange}
                />
              </div>
              <div className="login_right--form-input mt-3">
                <label>Code</label>
                <input
                  type="text"
                  id="Token"
                  className="form-control mt-1"
                  placeholder="Enter Code"
                  name="code"
                  defaultValue={data.code}
                  onChange={handleChange}
                />
              </div>
              <div className="login_right--form-input mt-3">
                <label>New Password</label>
                <input
                  type="password"
                  id="password"
                  className="form-control mt-1"
                  placeholder="Enter password"
                  name="password"
                  autoComplete="on"
                  defaultValue={data.password}
                  onChange={handleChange}
                />
              </div>
              <div className="login_right--form-input mt-3">
                <label>Confirm Password</label>
                <input
                  type="password"
                  id="pword"
                  className="form-control mt-1"
                  placeholder="Enter password"
                  name="password_confirm"
                  autoComplete="on"
                  defaultValue={data.password_confirm}
                  onChange={handleChange}
                />
              </div>
              <div className="login_right--form-input mt-3">
                <input
                  type="submit"
                  className="login_right--btn-login"
                  value="Reset Password"
                />
              </div>
              <p className="login_right--forgot-password mt-4">
                <a href="/"> Log In </a>
              </p>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
