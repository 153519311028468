import React, {useState, useEffect, useRef} from "react";
import Sidebar from "../../components/Sidebar";
import Menu from "../../components/menu.component";
import Table from "react-bootstrap/Table";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownMenu from "react-bootstrap/DropdownMenu";
import "rsuite/dist/rsuite.min.css";
import {useMemo} from "react";
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {Link} from "react-router-dom";
import EmptyOverviewPage from "./EmptyOverviewPage";
import EditStaff from "../Corporate/Staff/EditStaff";
import CircleLoader from "react-spinners/CircleLoader";
import Swal from "sweetalert2";
import ProgressBar from "react-bootstrap/ProgressBar";
import ReactDOM from "react-dom";
import ReactPaginate from 'react-paginate';
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import {RIFALY_USER_API, RIFALY_COOPERATE_API, RIFALY_BILLING_API} from "../../constants";
import Cookie from "universal-cookie";
const cookies = new Cookie();

const ErrorState = () => {
  return Swal.fire("Failed", "error");
}

export default function MainDashboard() {
  const [ error, setError ] = useState(null);
  const [ isLoaded, setIsLoaded ] = useState(false);
  const [ APIData, setAPIData ] = useState([])
  const [ message, setMessage ] = useState("");
  const {isLoading, isError} = useState();
  const [ stats, setStats ] = useState([]);

  var localStorage = window.localStorage;
  const info = JSON.parse(localStorage.getItem("user_data"));

  const user = cookies.get("user_data");
  const company = cookies.get("company_data");

  useEffect(() => {
    loadStaffData();
  }, []);

  const handleEditComplete = () => {
    loadStaffData();
  }
  useEffect(() => {
    theGeneralUserReport();
  }, []);


  const theGeneralUserReport = () => {

    const reportURL = `${RIFALY_COOPERATE_API}reports/stats/${cookies.get("company_data").id
      }`;
    fetch(reportURL, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: "Bearer " + cookies.get("access-token"),
      },
    })
      .then((result) => result.json())
      .then((result) => {
        setIsLoaded(true);
        setStats(result.data);
      }).catch((err) => {
        console.log(err);
      });
  }

  const loadStaffData = () => {

    fetch(
      `${RIFALY_COOPERATE_API}staffs/company/${cookies.get("company_data").id
      }`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + cookies.get("access-token"),
        },
      }
    )
      .then((result) => result.json())
      .then((result) => {
        setIsLoaded(true);
        // let theMaindata = result.data.filter((d)=>d.status && d.status.toLowerCase() != "deleted");
        setAPIData(result.data ? result.data : []);
      }).catch((err) => {
        console.log(err);
      })
  }
  const [ searchTerm, setSearchTerm ] = useState("");
  const [ pageNo, setPageNo ] = useState(0);

  const dataPerPage = 10;
  const pagesVisited = pageNo * dataPerPage;

  const handlePageChange = ({selected}) => {
    setPageNo(selected);
  };

  const displayStaffDataTable = APIData
    .filter((info) => {
      if (searchTerm === "") {
        return info;
      } else if (
        Object.values(info)
        .join("")
        .toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      info.subscription_group?.name
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
        ) {
        return info;
      }
      return false;
    })
    .slice(pagesVisited, pagesVisited + dataPerPage)
    .map((info) => {
      return (
        <tbody role="rowgroup" key={info.id}>

          <tr role="row">
            <td role="cell">
              <div><input type="checkbox" className="form-check-input" style={{marginLeft: 5}} id="usercheck" /></div>
            </td>
            <td role="cell">
              <div className="text-capitalize">{info.firstName + " " + info.lastName}</div>
            </td>
            <td role="cell">
              <div className="">{info.email ? info.email : "no email"}</div>
            </td>
            <td role="cell">
              <div className="text-capitalize">{info.subscription_group ? info.subscription_group.name : ""}</div>
            </td>
            <td role="cell">
              <div className="text-capitalize">{info.role}</div>
            </td>
            <td role="cell">
              <div>
                <div className="status"><div className={info.status && info.status.toLowerCase() == "active" ? "dot" : "dotinactive"}></div>{info.status ? info.status.toUpperCase() : "UnKnown Status"}</div>
              </div>
            </td>
            <td role="cell">
              <div>
                <Dropdown id="dropdown-action-button">
                  <Dropdown.Toggle variant='link'>
                    <a className="dropdown-ellipses icon-dropdown dropdown-toggle" id="dropdown-basic" aria-expanded="false">
                      <i className="bi bi-three-dots"></i>
                    </a>
                  </Dropdown.Toggle>
                  <DropdownMenu>
                    <Link to={"/staffDetail"} state={{info}} className="update-link_prop">View</Link>
                    <div className="edit-link"><EditStaff placement="end" data={info} onComplete={handleEditComplete} /></div>
                    <Dropdown.Item onClick={(e) => handleStaffStatusChange(e, info.id, "active")} ><div><span className="update-link" >Activate</span></div></Dropdown.Item>
                    <Dropdown.Item onClick={(e) => handleStaffStatusChange(e, info.id, "suspended")} ><div><span className="update-link" >Deactivate</span></div></Dropdown.Item>
                    <Dropdown.Item onClick={(e) => handledeleteStaff(e, parseInt(info.id))}><div><span className="delete-link" >Delete</span></div></Dropdown.Item>
                  </DropdownMenu>
                </Dropdown>
              </div>
            </td>
          </tr>
        </tbody>
      );
    });

  const pageCount = Math.ceil(
    APIData.filter((info) => {
      if (searchTerm === "") {
        return info;
      } else if (
        Object.values(info)
        .join("")
        .toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      info.subscription_group?.name
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
      ) {
        return info;
      }
      return false;
    }).length / dataPerPage
  );

  if (isError) {
    return <ErrorState />;
  }
  if (isLoading && !APIData) {
    return <CircleLoader color="#36d7b7" />;
  }
  if (APIData.length === 0) {
    return <EmptyOverviewPage />;
  }

  const handleStaffStatusChange = (e, SSCId, status) => {
    e.preventDefault();
    fetch(`${RIFALY_COOPERATE_API}staffs/update-status/${SSCId}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + cookies.get("access-token"),
      },
      body: JSON.stringify({status: status}),
    }).then((res) => res.json())
      .then((res) => {
        if (res.status === 200) {
          setMessage("Staff updated successfully");
          loadStaffData();
          // window.location.reload(true);
        } else {
          toast.error('Error Notification !', {
            position: toast.POSITION.TOP_LEFT
          });
        }
      });
  };

  const handledeleteStaff = (e, SDID) => {
    e.preventDefault();
    fetch(`${RIFALY_COOPERATE_API}staffs/update-status/${SDID}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + cookies.get("access-token"),
      },
      body: JSON.stringify({status: "deleted"}),
    }).then((res) => {
      if (res.status === 200) {
        const swalWithBootstrapButtons = Swal.mixin({
          customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-danger'
          },
          buttonsStyling: false
        })
        swalWithBootstrapButtons.fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, delete it!',
          cancelButtonText: 'No, cancel!',
          reverseButtons: true
        }).then((result) => {
          if (result.isConfirmed) {
            setMessage("Staff successfully deleted");
            loadStaffData();
            swalWithBootstrapButtons.fire(
              'Deleted!',
              'Your Staff has been deleted.',
              'success'
            )
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
          ) {
            swalWithBootstrapButtons.fire(
              'Cancelled',
              'The Staff is not deleted :)',
              'error'
            )
          }
        })
        // navigate("/staff", { replace: true });
      } else {
        toast.error('Error Notification !', {
          position: toast.POSITION.TOP_LEFT
        });
      }
    });
  };

  const handleExport = () => {
    let fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8";
    let ext = ".xlsx";
    var data = APIData.map((d) => {
      let r = d;
      r.subscription_group = d.subscriptionGroupId != null ? d.subscription_group.name : "no subscription group registered";
      delete r.corporateId;
      delete r.role;
      delete r.updatedAt;
      delete r.id;
      delete r.createdAt;
      delete r.emailVerified;
      delete r.refNo;
      delete r.avatar;
      delete r.userId;
      delete r.companyId;
      delete r.company;
      delete r.subscriptionGroupId;
      return r;
    });
    let ws = XLSX.utils.json_to_sheet(data);
    let wb = {Sheets: {data: ws}, SheetNames: [ "data" ]};
    let buffer = XLSX.write(wb, {bookType: "xlsx", type: "array"});
    let content = new Blob([ buffer ], {type: fileType});
    let filename = `Staff Information ${Date.now()}${ext}`;
    FileSaver.saveAs(content, filename);
  };

  return (
    <>
      <ToastContainer />
      {isLoading && (
        <ProgressBar
          size="xs"
          isIndeterminate
          w="100%"
          position="fixed"
          top="0"
        />
      )}
      <div className="row py-0">
        <div className="col-sm-2half order-1" id="sticky-sidebar">
          <div className="sticky-top">
            <Sidebar active="overview" />
          </div>
        </div>
        <div className="col order-2" id="main">
          <div className="container">
            <Menu />
            <div className="contents">
              <div className="row">
                <div className="col-md-12">
                  <div className="header mt-md-3">
                    <div className="header-body">
                      <h6 className="header-pretitle">Overview</h6>
                      <h1 className="header-title">Dashboard</h1>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div className="row">
                    <div className="col">
                      <div className="card-counter">
                        <p>
                          <span className="count-numbers">
                            Newspaper Delivered{" "}
                          </span>
                        </p>
                        <h3>
                          <span className="count-name">{stats.dailyDeliveryCount ? stats.dailyDeliveryCount : "0"}</span>
                        </h3>
                      </div>
                    </div>
                    <div className="col">
                      <div className="card-counter">
                        <p>
                          <span className="count-numbers">
                            Active Staff Members
                          </span>
                        </p>
                        <h3>
                          <span className="count-name">{stats.activeStaffs ? stats.activeStaffs : "0"}</span>
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="card-Main">
                    <div className="card_Main_initial">
                      <h5>
                        <span className="count-head">Basic Information</span>
                      </h5>
                      {/* <button type="button" className="btn btn-link">
                        <span className="bi bi-pencil"> Edit</span>
                      </button> */}
                    </div>
                    <div className="row pb-1">
                      <div className="col">
                        <h6>
                          <span className="count-head">
                            Profile Display Name
                          </span>
                        </h6>
                        <p>
                          <span className="count-info">{company.companyName}</span>
                        </p>
                      </div>
                      <div className="col">
                        <h6>
                          <span className="count-head">Name of Contact</span>
                        </h6>
                        <p>
                          <span className="count-info">
                            {user.first_name + " " + user.last_name}
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <h6>
                          <span className="count-head">Email Address</span>
                        </h6>
                        <p>
                          <span className="count-info">{user.email}</span>
                        </p>
                      </div>
                      <div className="col">
                        <h6>
                          <span className="count-head">Phone number</span>
                        </h6>
                        <p>
                          <span className="count-info">{user.phone}</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <div className="row align-items-end my-4">
                <div className="col">
                  <div className="row m-0">
                    <div className="col-md-4 m-0 p-0">
                      <div>
                        <form role="search">
                          <div className="input-group has-search">
                            <span className="bi bi-search form-control-feedback"></span>
                            <input
                              type="search"
                              name="search-form"
                              id="search-form"
                              className="form-control"
                              placeholder="Search Staff..."
                              onChange={(e) => {
                                setSearchTerm(e.target.value);
                                handlePageChange({selected: 0});
                              }} />
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-auto">
                  <a className="btn btn-primary lift" href="/AddStaff">
                    <span>
                      <i className="bi bi-plus"></i>
                    </span>
                    Add Staff
                  </a>
                </div>
              </div>
              <div className="row mt-2 body-content">
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="card">
                        <div className="card-header">
                          <h4 className="card-header-title  d-flex justify-content-left">
                            Staff Information
                          </h4>
                          <div>
                            <div>
                              <button filename="Corporate Staff.csv" onClick={handleExport} header="" className="btn btn-outline-primary btn-sm" target="_self">Export</button>
                            </div>
                          </div>
                        </div>

                        <Table
                          borderless
                          role="table"
                          className="table table-sm card-table"
                        >
                          <thead>
                            <tr role="row">
                              <th
                                colSpan="1"
                                role="columnheader"
                                title="Toggle SortBy"
                                style={{cursor: "pointer"}}
                              >
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  style={{marginLeft: 5}}
                                  id="usercheck"
                                />
                              </th>
                              <th
                                colSpan="1"
                                role="columnheader"
                                title="Toggle SortBy"
                                style={{cursor: "pointer"}}
                              >
                                Full name
                              </th>
                              <th
                                colSpan="1"
                                role="columnheader"
                                title="Toggle SortBy"
                                style={{cursor: "pointer"}}
                              >
                                email
                              </th>
                              <th
                                colSpan="1"
                                role="columnheader"
                                title="Toggle SortBy"
                                style={{cursor: "pointer"}}
                              >
                                Subscription Group
                              </th>
                              <th
                                colSpan="1"
                                role="columnheader"
                                title="Toggle SortBy"
                                style={{cursor: "pointer"}}
                              >
                                Role
                              </th>
                              <th
                                colSpan="1"
                                role="columnheader"
                                title="Toggle SortBy"
                                style={{cursor: "pointer"}}
                              >
                                status
                              </th>
                              <th
                                colSpan="1"
                                role="columnheader"
                                title="Toggle SortBy"
                                style={{cursor: "pointer"}}
                              >
                                action
                              </th>
                            </tr>
                          </thead>
                          {displayStaffDataTable}
                        </Table>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-5">
                    <div className="col"></div>
                    <div className="col-auto">
                      <div>
                        <ReactPaginate
                          previousLabel={"<"}
                          nextLabel={">"}
                          pageCount={pageCount}
                          onPageChange={handlePageChange}
                          breakClassName={"page-item"}
                          breakLinkClassName={"page-link"}
                          containerClassName={"pagination"}
                          pageClassName={"page-item"}
                          pageLinkClassName={"page-link"}
                          previousClassName={"page-item"}
                          previousLinkClassName={"page-link"}
                          nextClassName={"page-item"}
                          nextLinkClassName={"page-link"}
                          activeClassName={"active"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
