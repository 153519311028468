import React from "react";
import Sidebar from "../../../components/Sidebar";
import Menu from "../../../components/menu.component";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import Application from "./subscriptionPublications";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { RIFALY_USER_API, RIFALY_COOPERATE_API } from "../../../constants";
import Cookie from "universal-cookie";
const cookies = new Cookie();

function AddSubscriptionGroup() {
  var navigate = useNavigate();
  const [message, setMessage] = useState("");
  const [inputFields, setInputFields] = useState({
    groupName: "",
    corporateCompany: "",
    subscriptionGroupCharging: "",
  });
  const [sel, setSel] = useState([]);
  const [addsubscriptiongroup, setaddsubscriptiongroup] = useState([]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    inputFields[name] = value;
    setInputFields(inputFields);
  };

  let handleSubmit = async (e) => {
    e.preventDefault();
    let body = inputFields;
    body.selections = sel;
    const company = cookies.get("company_data");

    const data = {
      companyId: company.id,
      name: body.groupName.trim(),
      papers: body.selections,
    };
    try {
      let res = await fetch(`${RIFALY_COOPERATE_API}subscription-groups`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + cookies.get("access-token"),
        },
      });
      let resJson = await res.json();
      if (res.status === 201) {
        setInputFields("");
        // showToastMessage();
        setMessage("Subscription Group created successfully");
        navigate("/subscriptiongroup", { replace: true });
      } else {
        toast.error(res.message, {
          position: toast.POSITION.TOP_RIGHT
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  //   const headerKeys = Object.keys(Object.assign({}, ...array));
  const handleSave = (selection) => {
    setSel(selection);
  };

  const showToastMessage = () => {
    toast.success('Subscription Group Created !', {
        position: toast.POSITION.TOP_RIGHT
    });
  };
  const { groupName } = inputFields;

  return (
    <>
      <ToastContainer />
      <div className="row py-0">
        <div className="col-sm-2half order-1" id="sticky-sidebar">
          <div className="sticky-top">
            <Sidebar active="staff" />
          </div>
        </div>
        <div className="col order-2" id="main">
          <div className="container">
            <Menu />
            <div className="contents">
              <div className="row justify-content-center">
                <div className="col-12 col-lg-10 col-xl-12">
                  <div className="header mt-md-3">
                    <nav aria-label="breadcrumb mb-2">
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                          <a href="/Overview">Dashboard</a>
                        </li>
                        <li className="breadcrumb-item">
                          <a href="/staff">Corporate</a>
                        </li>
                        <li className="breadcrumb-item">
                          <a href="/subscriptiongroup">Subscription Groups</a>
                        </li>
                        <li
                          className="breadcrumb-item active"
                          aria-current="page"
                        >
                          Add subscription group
                        </li>
                      </ol>
                    </nav>
                  </div>
                  <div className="content">
                    <div className="row mt-3">
                      <div className="col-md-12">
                        <div
                          className="card mt-2"
                          style={{
                            background: "#FFFFFF",
                            border: "1px solid #E3EBF6",
                            borderRadius: "10px",
                          }}
                        >
                          <div className="px-4 pt-4">
                            <div className="row">
                              <div className="col-12">
                                <h6 className="header-pretitle">Overview</h6>
                                <h1 className="header-title">
                                  Add Subscription Group
                                </h1>
                              </div>
                            </div>
                            <hr />
                            <div className="row">
                              <div className="col-md-12">
                                <form onSubmit={handleSubmit}>
                                  <div className="row">
                                    <div className="col-md-12">
                                      <div className="form-group">
                                        <label
                                          htmlFor="firstName"
                                          className="form-label"
                                        >
                                          Group Name
                                        </label>
                                        <input
                                          className="form-select"
                                          type="text"
                                          name="groupName"
                                          defaultValue={groupName}
                                          onChange={(event) =>
                                            handleChange(event)
                                          }
                                          id="groupName"
                                          required
                                          placeholder="Enter Group Name"
                                        />
                                        <p className="errors"></p>
                                      </div>
                                    </div>
                                    <Application onSave={handleSave} state={{ addsubscriptiongroup }} />
                                  </div>
                                  <div
                                    className="row my-4"
                                    style={{ justifyContent: "flex-end" }}
                                  >
                                    <div className="col-auto">
                                      <button
                                        className="btn btn-primary lift"
                                        type="submit"
                                      >
                                        Submit
                                      </button>
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddSubscriptionGroup;
