import React, { useState, useEffect } from "react";
import Sidebar from "../../../components/Sidebar";
import Menu from "../../../components/menu.component";
import Table from "react-bootstrap/Table";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownMenu from "react-bootstrap/DropdownMenu";
import { Link } from "react-router-dom";
import "rsuite/dist/rsuite.min.css";
import Swal from "sweetalert2";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CircleLoader from "react-spinners/CircleLoader";
import EmptySubscriptionPage from "./EmptySubscriptionGroup";
import ReactPaginate from 'react-paginate';
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import moment from "moment";
import "moment-timezone";
import { RIFALY_USER_API, RIFALY_COOPERATE_API } from "../../../constants";
import Cookie from "universal-cookie";
const cookies = new Cookie();


const ErrorState = () => {
  return Swal.fire("Failed", "error");
};

function SubscriptionGroup() {
  const [showedit, setshowedit] = useState(false);
  const [message, setMessage] = useState("");
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [APIData, setAPIData] = useState([])

  const theSubcriptionGroupData=()=>{
    try {
      fetch(
        `${RIFALY_COOPERATE_API}subscription-groups/company/${
          cookies.get("company_data").id
        }`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + cookies.get("access-token"),
          },
        }
      )
        .then((data) => data.json())
        .then((result) => {
          setIsLoaded(true);
          setAPIData(result.data?result.data:[]);
        });
    } catch (err) {
      console.log(err);
    }
  }
  useEffect(() => {
    theSubcriptionGroupData();
  }, []);

  const { isLoading, isError } = useState();
  const [searchTerm, setSearchTerm] = useState("");
  const [pageNo, setPageNo] = useState(0);

  const dataPerPage = 10;
  const pagesVisited = pageNo * dataPerPage;
  
  const handlePageChange = ({ selected }) => {
    setPageNo(selected);
  }; 

  const displayStaffDataTable = APIData
    .filter((item) => {
      if (searchTerm === "") {
        return item;
      } else if (
        Object.values(item).join('').toLowerCase().includes(searchTerm.toLowerCase())
      ) {
        return item;
      }
      return false;
    })
    .slice(pagesVisited, pagesVisited + dataPerPage)
    .map((item) => {
      return (
                <tbody role="rowgroup" key={item.id}>
                                                        <tr role="row">
                                                          <td role="cell">
                                                            <div>
                                                              <input
                                                                type="checkbox"
                                                                className="form-check-input"
                                                                style={{ marginLeft: 5 }}
                                                                id="usercheck"
                                                              />
                                                            </div>
                                                          </td>
                                                          <td role="cell">
                                                            <div className="text-capitalize">
                                                              {item.name}
                                                            </div>
                                                          </td>
                                                          <td role="cell">{item.staffs.length}</td>
                                                          <td role="cell">
                                                            {moment(item.createdAt).format(
                                                              "DD/MM/YYYY"
                                                            )}
                                                          </td>
                                                          <td role="cell">
                                                            <div>
                                                              <div className="status"><div className={item.status && item.status.toLowerCase() == "active"? "dot" : "dotinactive"}></div>{item.status? item.status.toUpperCase(): "UnKnown Status"}</div>
                                                            </div>
                                                          </td>
                                                          <td role="cell">
                                                            <div>
                                                              <Dropdown id="dropdown-action-button">
                                                                <Dropdown.Toggle variant="link">
                                                                  <a
                                                                    className="dropdown-ellipses icon-dropdown dropdown-toggle"
                                                                    id="dropdown-basic"
                                                                    aria-expanded="false"
                                                                  >
                                                                    <i className="bi bi-three-dots"></i>
                                                                  </a>
                                                                </Dropdown.Toggle>
                                                                  <DropdownMenu>
                                                                      <Link to={"/subscriptionGroupDetail"} state={{ item }} className="update-link_prop">View</Link>
                                                                      {/* <div><Link to={"/EditsubscriptionGroup"} state={{ item }} className="update-link_prop">Edit</Link></div> */}
                                                                      <Dropdown.Item onClick={(e) => handleSubscriptionGroupStatusChange(e, item.id,"active")}><div><span className="update-link">Activate</span></div></Dropdown.Item>
                                                                      <Dropdown.Item onClick={(e) => handleSubscriptionGroupStatusChange(e, item.id,"suspended")} ><div><span className="update-link">Deactivate</span></div></Dropdown.Item>
                                                                      {/* <Dropdown.Item onClick={(e) => handledeleteSubscriptionGroup(e, parseInt(item.id))} ><div><span className="delete-link">Delete</span></div></Dropdown.Item> */}
                                                                  </DropdownMenu>
                                                              </Dropdown>
                                                            </div>
                                                          </td>
                                                        </tr>
                                                    </tbody>
      );
    });
  const pageCount = Math.ceil(
    APIData.filter((item) => {
      if (searchTerm === "") {
        return item;
      } else if (
        Object.values(item).join('').toLowerCase().includes(searchTerm.toLowerCase())
      ) {
        return item;
      }
      return false;
    }).length / dataPerPage
  );

  if (isError) {
    return <ErrorState />;
  }
  if (isLoading && !APIData) {
    return <CircleLoader color="#36d7b7" />;
  }
  if (APIData.length === 0) {
    return <EmptySubscriptionPage />;
  }

  const handleSubscriptionGroupStatusChange = (e, sgcId,status) => {
    e.preventDefault();
    fetch(`${RIFALY_COOPERATE_API}subscription-groups/${sgcId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + cookies.get("access-token"),
      },
      body: JSON.stringify({status:status}),
    }).then((res) => res.json())
      .then((res) => {
        if (res.status === 200) {
          setMessage("Subscription Group updated successfully");
          theSubcriptionGroupData();   
        } else {
          toast.error(res.message, {
            position: toast.POSITION.TOP_LEFT
          });
        }

      });
  };
  
  const handledeleteSubscriptionGroup = (e, Sgdid) => {
    e.preventDefault();
    fetch(`${RIFALY_COOPERATE_API}subscription-groups/${Sgdid}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + cookies.get("access-token"),
      },
      body: JSON.stringify({status: "deleted"}),
    }).then((res) => {
      if (res.status === 200) {
        const swalWithBootstrapButtons = Swal.mixin({
          customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-danger'
          },
          buttonsStyling: false
        })
        swalWithBootstrapButtons.fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, delete it!',
          cancelButtonText: 'No, cancel!',
          reverseButtons: true
        }).then((result) => {
          if (result.isConfirmed) {
            setMessage("Subscription Group successfully deleted");
            theSubcriptionGroupData();
            swalWithBootstrapButtons.fire(
              'Deleted!',
              'Your Subscription Group has been deleted.',
              'success'
            )
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
          ) {
            swalWithBootstrapButtons.fire(
              'Cancelled',
              'The Subscription Group is not deleted :)',
              'error'
            )
          }
        })
      } else {
        toast.error('Error Notification !', {
          position: toast.POSITION.TOP_LEFT
        });
      }
    });
  };

  const handleExport = () => {
    let fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8";
    let ext = ".xlsx";
    var data = APIData.map((d) => {
      let r = d;
      r.papers = d.papers.length > 0 ? d.papers.map((paper)=>paper.title).join(" , "): "no papers subscribed";
      r.staffs = d.staffs.length;
      delete r.subscribedPublications;
      delete r.createdBy;
      delete r.updatedAt;
      delete r.users;
      delete r.id;
      delete r.companyId;
      delete r.company;

      r.createdAt=(moment(d.createdAt).format("DD/MM/YYYY"));
      r.updatedAt=(moment(d.updatedAt).format("DD/MM/YYYY"));
    //   r.category = d.categoryId == 1 ? "Newspaper" : "Magazine";
      return r;
    });
    let ws = XLSX.utils.json_to_sheet(data);
    let wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    let buffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    let content = new Blob([buffer], { type: fileType });
    let filename = `Subscription Groups ${Date.now()}${ext}`;
    FileSaver.saveAs(content, filename);
  };

  return (
    <>
      <ToastContainer />
      <div className="row py-0">
        <div className="col-sm-2half order-1" id="sticky-sidebar">
          <div className="sticky-top">
            <Sidebar active="staff" />
          </div>
        </div>
        <div className="col order-2" id="main">
          <div className="container">
            <Menu />
            <div className="contents">
              <div className="row">
                <div className="col-md-12">
                  <div className="header mt-md-3">
                    <nav aria-label="breadcrumb mb-2">
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                          <a href="/Overview">Dashboard</a>
                        </li>
                        <li className="breadcrumb-item">
                          <a href="/staff">Corporate</a>
                        </li>
                        <li
                          className="breadcrumb-item active"
                          aria-current="page"
                        >
                          Subscription Groups
                        </li>
                      </ol>
                    </nav>
                    <div className="header-body">
                      <h6 className="header-pretitle">Overview</h6>
                      <h1 className="header-title">Corporate</h1>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <ul className="nav nav-tabs">
                    <li className="nav-item">
                      <a aria-current="page" className="nav-link" href="/Staff">
                        Staff's
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link active" href="/subscriptiongroups">
                        Subscription Groups
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div>
                <div className="row align-items-end my-4">
                  <div className="col">
                    <div className="row m-0">
                      <div className="col-md-4 m-0 p-0">
                        <div>
                          <form role="search">
                            <div className="input-group has-search">
                              <span className="bi bi-search form-control-feedback"></span>
                              <input
                                 type="search"
                                 name="search-form"
                                 id="search-form"
                                 className="form-control"
                                 placeholder="Search Staff..."
                                 onChange={(e) => {
                                  setSearchTerm(e.target.value);
                                  handlePageChange({ selected: 0 });
                                }}                                />
                            </div>
                          </form>
                        </div>
                      </div>
                      <div className="col-md-4">
                      </div>
                    </div>
                  </div>
                  <div className="col-auto">
                    <a
                      className="btn btn-primary lift"
                      href="/addsubscriptiongroup"
                    >
                      <span>
                        <i className="bi bi-plus"></i>
                      </span>
                      Add Corporate Subscriber Groups
                    </a>
                  </div>
                </div>
                <div className="row mt-2 body-content">
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="card">
                          <div className="card-header">
                            <h4 className="card-header-title  d-flex justify-content-left">
                              {" "}
                              Groups Information
                            </h4>
                            <div>
                              <div>
                              <button filename="subscriptionGroup.csv" onClick={handleExport} header="" className="btn btn-outline-primary btn-sm" target="_self">Export</button>
                              </div>
                            </div>
                          </div>
                          <Table
                            borderless
                            role="table"
                            className="table table-sm card-table"
                          >
                            <thead>
                              <tr role="row">
                                <th
                                  colSpan="1"
                                  role="columnheader"
                                  title="Toggle SortBy"
                                  style={{ cursor: "pointer" }}
                                >
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    style={{ marginLeft: 5 }}
                                    id="usercheck"
                                  />
                                </th>
                                <th
                                  colSpan="1"
                                  role="columnheader"
                                  title="Toggle SortBy"
                                  style={{ cursor: "pointer" }}
                                >
                                  Group name
                                </th>
                                <th
                                  colSpan="1"
                                  role="columnheader"
                                  title="Toggle SortBy"
                                  style={{ cursor: "pointer" }}
                                >
                                  Total Subscribers
                                </th>
                                <th
                                  colSpan="1"
                                  role="columnheader"
                                  title="Toggle SortBy"
                                  style={{ cursor: "pointer" }}
                                >
                                  Date Created
                                </th>
                                <th
                                  colSpan="1"
                                  role="columnheader"
                                  title="Toggle SortBy"
                                  style={{ cursor: "pointer" }}
                                >
                                  Status
                                </th>
                                <th
                                  colSpan="1"
                                  role="columnheader"
                                  title="Toggle SortBy"
                                  style={{ cursor: "pointer" }}
                                >
                                  Action
                                </th>
                              </tr>
                            </thead>
                            {displayStaffDataTable}         
                          </Table>
                        </div>
                      </div>
                    </div>
                    <div className="row mb-5">
                      <div className="col"></div>
                      <div className="col-auto">
                        <div>
                          <ReactPaginate
                                previousLabel={"<"}
                                nextLabel={">"}
                                pageCount={pageCount}
                                onPageChange={handlePageChange}
                                breakClassName={"page-item"}
                                breakLinkClassName={"page-link"}
                                containerClassName={"pagination"}
                                pageClassName={"page-item"}
                                pageLinkClassName={"page-link"}
                                previousClassName={"page-item"}
                                previousLinkClassName={"page-link"}
                                nextClassName={"page-item"}
                                nextLinkClassName={"page-link"}
                                activeClassName={"active"}
                              />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SubscriptionGroup;
