import React, {useState, useEffect} from "react";
import Sidebar from "../../components/Sidebar";
import Menu from "../../components/menu.component";
import Table from "react-bootstrap/Table";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import ClipLoader from "react-spinners/ClipLoader";
import moment from "moment";
import "moment-timezone";
import Swal from "sweetalert2";
import EmptyReportPage from "./EmptyReportPage";
import {useNavigate} from "react-router-dom";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import ReactPaginate from 'react-paginate';
import {RIFALY_BILLING_API} from "../../constants";
import Cookie from "universal-cookie";
import { v4 as uuidv4 } from 'uuid';
import {DateTime} from 'luxon';

const cookies = new Cookie();

const ErrorState = () => {
  return Swal.fire("Failed", "error");
};

function UsageReport() {
  const [ message, setMessage ] = useState("");
  var navigate = useNavigate();

  const [ error, setError ] = useState(null);
  const [loading, setLoading] = useState(false);
  const [ APIData, setAPIData ] = useState([]);
  const [ filteredData, setFilteredData ] = useState(APIData);
  const [ products, setProducts ] = useState([]);
  const [ allProducts, setAllProducts ] = useState([]);
  const [ showRange, setShowRange ] = useState(false);
  // const [ start, setStart ] = useState((new Date()).toISOString());
  // const [ end, setEnd ] = useState((new Date()).toISOString());
  const [ start, setStart ] = useState(DateTime.fromMillis(Date.now()).startOf('day').toISO({includeOffset: false}));
  const [ end, setEnd ] = useState(DateTime.fromMillis(Date.now()).toISO({includeOffset: false}));
  const [data, setData] = useState([]);
  const [ searchTerm, setSearchTerm ] = useState("");

  useEffect(() => {
    loadStaffData();
  }, []);


  const loadStaffData = () => {
    try {
      fetch(
        `${RIFALY_BILLING_API}corporates/usage/?id=${cookies.get("company_data").id}&startDate=${start}&endDate=${end}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + cookies.get("access-token"),
          },
        }
      )
        .then((result) => result.json())
        .then((result) => {
          setAPIData(result.data);
          setFilteredData(result.data);
        });
    } catch (err) {
      console.log("error:", err);
    }
  }

  const [ pageNo, setPageNo ] = useState(0);
  const dataPerPage = 10;
  const pagesVisited = pageNo * dataPerPage;

  const handlePageChange = ({selected}) => {
    setPageNo(selected);
  };
 const randomId = ()=>{
  return uuidv4();
 }
  const displayUsageReportDataTable = filteredData
    .filter((item) => {
      if (searchTerm === "") {
        return item;
      } else if (
        Object.values(item)
          .join("").toLowerCase().includes(searchTerm.toLowerCase()) 
        ) {
        return item;
      }
      return false;
    })
    .slice(pagesVisited, pagesVisited + dataPerPage)
    .map((item, index) => {
      let publishingdate = new Date(item.deliveryTimestamp ? item.deliveryTimestamp : " ");

      return (
        <tbody role="rowgroup" key={randomId()}>
          <tr role="row" key={randomId()}>
            <td role="cell">
              <div className="text-capitalize"><span>{index + 1}</span></div>
            </td>
            <td role="cell">
              <div className="text-capitalize"> {item.staffName ? item.staffName : " "}</div>
            </td>
            <td role="cell">
              <div className="text-capitalize">{item.subscription_group ? item.subscription_group : " "}</div>
            </td>
            <td role="cell">
              <div className="text-capitalize">{item.paperTitle ? item.paperTitle : " "}</div>
            </td>
            <td role="cell">
              <div className="text-capitalize">{publishingdate.toLocaleDateString() ? publishingdate.toLocaleDateString() : " "}</div>
            </td>
            <td role="cell">
              <div className="text-capitalize">{item.delivery}</div>
            </td>
            <td role="cell">
              <div className="text-capitalize">{item.read}</div>
            </td>
            <td role="cell">
              <div className="text-capitalize">{publishingdate.toLocaleDateString() ? publishingdate.toLocaleDateString() : " "}</div>
            </td>
          </tr>
        </tbody>
      );
    });
    const pageCount = Math.ceil(
      filteredData.filter((item) => {
        if (searchTerm === "") {
          return item;
        } else if (
          Object.values(item)
          .join("").toLowerCase().includes(searchTerm.toLowerCase())
           ) {
          return item;
        }
        return false;
      }).length / dataPerPage
    );

  const {isLoading, isError} = useState();

  if (isError) {
    return <ErrorState />;
  }

  if (isLoading && !APIData) {
    return <ClipLoader color="#36d7b7" />;
  }

  const handleExport = () => {
    let fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8";
    let ext = ".xlsx";
    var data = APIData.map((d) => {
      let r = d;
      delete r.companyId;
      delete r.createdAt;
      delete r.paperId;
      delete r.deliveryTimestamp;
      delete r.deliveryDate;
      delete r.userId;
      delete r.refNo;
      delete r.id;
      r.date = (moment(d.date).format("DD/MM/YYYY"));

      return r;
    });
    let ws = XLSX.utils.json_to_sheet(data);
    let wb = {Sheets: {data: ws}, SheetNames: [ "data" ]};
    let buffer = XLSX.write(wb, {bookType: "xlsx", type: "array"});
    let content = new Blob([ buffer ], {type: fileType});
    let filename = `Usage Report ${Date.now()}${ext}`;
    FileSaver.saveAs(content, filename);
  };

  const handleSearchDate = () => {
    setLoading(true);
    let startDate = moment(start).format("YYYY-MM-DD");
    let endDate = moment(end).format("YYYY-MM-DD");
    try {
      fetch(
        `${RIFALY_BILLING_API}corporates/usage/?id=${cookies.get("company_data").id}&startDate=${startDate}&endDate=${endDate}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + cookies.get("access-token"),
          },
        }
      )
        .then((result) => result.json())
        .then((result) => {
          setAPIData(result.data);
          setFilteredData(result.data);
          setLoading(false);
        });
    } catch (err) {
      console.log("error:", err);
      setLoading(false);
    }
  
};
  const handleDateChange = (e) => {
    if (e.target.id === 'start') setStart((new Date(e.target.value.trim())).toISOString());
    else setEnd((new Date(e.target.value.trim())).toISOString());
  };

  return (
    <>
      {isLoading && (
        <ClipLoader color="#36d7b7" />
      )}
      <div className="row py-0">
        <div className="col-sm-2half order-1" id="sticky-sidebar">
          <div className="sticky-top">
            <Sidebar active="reports" />
          </div>
        </div>
        <div className="col order-2" id="main">
          <div className="container">
            <Menu />
            <div className="contents">
              <div className="row">
                <div className="col-md-12">
                  <div className="header mt-md-3">
                    <nav aria-label="breadcrumb mb-2">
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                          <a href="/Overview">Dashboard</a>
                        </li>
                        <li className="breadcrumb-item">
                          <a href="/usagereport">Reports</a>
                        </li>
                        <li
                          className="breadcrumb-item active"
                          aria-current="page"
                        >
                          Usage report
                        </li>
                      </ol>
                    </nav>
                    <div className="header-body">
                      <h6 className="header-pretitle">Overview</h6>
                      <h1 className="header-title">Reports</h1>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <ul className="nav nav-tabs">
                    <li className="nav-item">
                      <a
                        aria-current="page"
                        className="nav-link active"
                        href="/usagereport"
                      >
                        {" "}
                        Usage Report
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="/summaryreports">
                        Summary Report
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div>
                <div className="row align-items-end my-4">
                  <div className="col">
                    <div className="row m-0">
                      <div className="col-md-4 m-0 p-0">
                        <div>
                          <form role="search">
                            <div className="input-group has-search">
                              <span className="bi bi-search form-control-feedback"></span>
                              <input
                                type="search"
                                name="search-form"
                                id="search-form"
                                className="form-control"
                                placeholder="Search Report..."
                                onChange={(e) => {
                                  setSearchTerm(e.target.value);
                                  handlePageChange({selected: 0});
                                }}
                              />
                            </div>
                          </form>
                        </div>
                      </div>
                        <div className='col-md-3'>
                          <input type="date" className="form-control" placeholder="Select Start Date" defaultValue={start} onChange={handleDateChange} id='start' />
                        </div>
                        <div className='col-md-3'>
                          <input type="date" className="form-control" placeholder="Select End Date" defaultValue={start} onChange={handleDateChange} id='end' />
                        </div>
                        <div className='col-md-2'>
                          <button
                            className='btn btn-primary'
                            onClick={handleSearchDate}>
                            <span className='fe fe-search'></span>&nbsp;Search
                          </button>
                        </div>
                    </div>
                  </div>
                  <div className="col-auto">
                  </div>
                </div>
                {loading ?  (
                    <div className="col-md-12 text-center">
                      <ClipLoader color="#36d7b7" />
                      <p>Loading data...</p>
                    </div>
                  ) 
                : (
                (APIData.length > 0) ? (
                    <div className="row mt-2 body-content">
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="card">
                            <div className="card-header">
                              <h4 className="card-header-title  d-flex justify-content-left">
                                Usage Report Information
                              </h4>
                              <div>
                                <div>
                                  <button filename="Corporate Staff.csv" onClick={handleExport} header="" className="btn btn-outline-primary btn-sm" target="_self">Export</button>
                                </div>
                              </div>
                            </div>
                            <Table
                              variant="light"
                              borderless
                              role="table"
                              className="table table-sm card-table"
                            >
                              <thead>
                                <tr role="row">
                                  <th
                                    colSpan="1"
                                    role="columnheader"
                                    title="Toggle SortBy"
                                    style={{cursor: "pointer"}}
                                  >
                                   S/NO
                                  </th>
                                  <th
                                    colSpan="1"
                                    role="columnheader"
                                    title="Toggle SortBy"
                                    style={{cursor: "pointer"}}
                                  >
                                    full name
                                  </th>
                                  <th
                                    colSpan="1"
                                    role="columnheader"
                                    title="Toggle SortBy"
                                    style={{cursor: "pointer"}}
                                  >
                                    Subscriber group
                                  </th>
                                  <th
                                    colSpan="1"
                                    role="columnheader"
                                    title="Toggle SortBy"
                                    style={{cursor: "pointer"}}
                                  >
                                    PAPER NAME
                                  </th>
                                  <th
                                    colSpan="1"
                                    role="columnheader"
                                    title="Toggle SortBy"
                                    style={{cursor: "pointer"}}
                                  >
                                    PUB DATE
                                  </th>
                                  <th
                                    colSpan="1"
                                    role="columnheader"
                                    title="Toggle SortBy"
                                    style={{cursor: "pointer"}}
                                  >
                                    DELIVERY STATUS
                                  </th>
                                  <th
                                    colSpan="1"
                                    role="columnheader"
                                    title="Toggle SortBy"
                                    style={{cursor: "pointer"}}
                                  >
                                    READ STATUS
                                  </th>
                                  <th
                                    colSpan="1"
                                    role="columnheader"
                                    title="Toggle SortBy"
                                    style={{cursor: "pointer"}}
                                  >
                                    download date
                                  </th>
                                </tr>
                              </thead>
                              {displayUsageReportDataTable}
                            </Table>
                          </div>
                        </div>
                      </div>
                      <div className="row mb-5">
                        <div className="col"></div>
                        <div className="col-auto">
                          <div>
                            <ReactPaginate
                              previousLabel={"<"}
                              nextLabel={">"}
                              pageCount={pageCount}
                              onPageChange={handlePageChange}
                              breakClassName={"page-item"}
                              breakLinkClassName={"page-link"}
                              containerClassName={"pagination"}
                              pageClassName={"page-item"}
                              pageLinkClassName={"page-link"}
                              previousClassName={"page-item"}
                              previousLinkClassName={"page-link"}
                              nextClassName={"page-item"}
                              nextLinkClassName={"page-link"}
                              activeClassName={"active"}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  ) : (
                    <EmptyReportPage />
                    )
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UsageReport;

