import React, { useState, useEffect } from 'react';
import Sidebar from '../../../components/Sidebar';
import Menu from '../../../components/menu.component';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Swal from 'sweetalert2';
import Col from 'react-bootstrap/Col';
import { useLocation, Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Offcanvas from 'react-bootstrap/Offcanvas';
import SubscriptionGroup from './SubscriptionGroup';
import Application from './subscriptionPublications';
import { ToastContainer, toast } from 'react-toastify';
import Checkbox from './checkbox';
import { RIFALY_USER_API, RIFALY_COOPERATE_API } from '../../../constants';
import Cookie from 'universal-cookie';
import StaffEmptyState from '../Staff/StaffEmptyState';
const cookies = new Cookie();

export default function EditSubscriptionGroup(props) {
  var navigate = useNavigate();
  const location = useLocation();
  const state = location.state.item;

  const [allformerSelectedbox, setAllformerSelectedbox] = useState([state]);
  const [newSelectedbox, setNewSelectedbox] = useState([state]);
  const [sel, setSel] = useState([]);
  const [submitEnabled, setSubmitEnabled] = useState(true);
  const [editSubscriptionObject, seteditSubscriptionObject] = useState({
    name: state.name,
    companyId: state.companyId,
    papers: sel,
    id: state.id,
  });
  const [message, setMessage] = useState('');
  const [companySubscriptionGroups, setCompanySubscriptionGroups] = useState(
    [],
  );

  const handleChange = (e) => {
    e.preventDefault();
    let name = e.target.name;
    let value = e.target.value.trim();
    if (name && name.length > 0 && name != editSubscriptionObject.name) {
      let data = editSubscriptionObject;
      data[name] = value;

      seteditSubscriptionObject(data);
      setSubmitEnabled(false);
    } else setSubmitEnabled(true);
  };

  const handleSubscriptionGroupUpdate = (e) => {
    e.preventDefault();
    const accessToken = cookies.get('access-token');
    let body = editSubscriptionObject;
    body.papers = sel;

    if (body.companyId === cookies.get('company_data').id) {
      fetch(`${RIFALY_COOPERATE_API}subscription-groups/${state.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(body),
      }).then((response) => {
        if (response.status === 200) {
          // showToastMessage();
          setMessage('Subscription Group updated successfully');
          navigate("/subscriptiongroup", { replace: true });
          // props.onComplete();
        } else {
          toast.error('Error Notification !', {
            position: toast.POSITION.TOP_LEFT,
          });
        }
      });
    }
  };
  //   const headerKeys = Object.keys(Object.assign({}, ...array));
  const handleSave = (selection) => {
    if (
      selection &&
      selection.length > 0 &&
      selection != editSubscriptionObject.papers
    ) {
      setSel(selection);
      setSubmitEnabled(false);
    }
  };

  const showToastMessage = () => {
    toast.success('Subscription Group Updated !', {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  return (
    <>
      <div className='row py-0'>
        <div
          className='col-sm-2half order-1'
          id='sticky-sidebar'>
          <div className='sticky-top'>
            <Sidebar active='staff' />
          </div>
        </div>
        <div
          className='col order-2'
          id='main'>
          <div className='container'>
            <Menu />
            <div className='contents'>
              <div className='row'>
                <div className='col-md-12'>
                  <div className='header mt-md-3'>
                    <nav aria-label='breadcrumb mb-2'>
                      <ol className='breadcrumb'>
                        <li className='breadcrumb-item'>
                          <a href='/Overview'>Dashboard</a>
                        </li>
                        <li className='breadcrumb-item'>
                          <a href='/staff'>Corporates</a>
                        </li>
                        <li className='breadcrumb-item'>
                          <a href='/subscriptiongroup'>Subscription Groups</a>
                        </li>
                        <li
                          className='breadcrumb-item active'
                          aria-current='page'>
                          Edit Subscription Group
                        </li>
                      </ol>
                    </nav>
                  </div>

                  <div className='content'>
                    <div className='row mt-3'>
                      <div className='col-md-12'>
                        <div className='card pt-2 mt-2'>
                          <div className='px-4 pt-4'>
                            <div className=''>
                              <h6 className='header-pretitle'>Overview</h6>

                              <h1 className='header-title'>
                                Edit Subscription Group
                              </h1>
                            </div>

                            <hr />

                            <div className='row'>
                              <div className='col-md-12'>
                                <form>
                                  <div className='row'>
                                    <div className='col-md-6'>
                                      <div className='form-group'>
                                        <label
                                          htmlFor='name'
                                          className='form-label'>
                                          Group Name
                                        </label>
                                        <input
                                          type='text'
                                          className={'form-control'}
                                          id='groupName'
                                          name='groupName'
                                          defaultValue={state.name}
                                          onChange={(event) =>
                                            handleChange(event)
                                          }
                                        />
                                      </div>
                                    </div>
                                    <div className='col-md-6'>
                                      <div className='form-group'>
                                        <label
                                          htmlFor='name'
                                          className='form-label'>
                                          Company
                                        </label>
                                        <input
                                          type={'text'}
                                          className='form-control'
                                          id='companyId'
                                          name='companyId'
                                          disabled={true}
                                          defaultValue={
                                            state.company
                                              ? state.company.companyName
                                              : state.companyId
                                          }
                                        />
                                      </div>
                                    </div>
                                    <div className='col-md-12 row'>
                                      <div className='col-md-12'>
                                        <label className='form-label'>
                                          {' '}
                                          Publications
                                        </label>
                                      </div>
                                      <Application
                                        onSave={handleSave}
                                        state={{ allformerSelectedbox }}
                                        isEdit={true}
                                        papers={state.papers}
                                      />
                                    </div>
                                    <div
                                      className='row my-4'
                                      style={{ justifyContent: 'flex-end' }}>
                                      <div className='col-auto'>
                                        <button
                                          id='submitbtn'
                                          disabled={submitEnabled}
                                          className='btn btn-primary lift'
                                          type='submit'
                                          onClick={(e) =>
                                            handleSubscriptionGroupUpdate(e)
                                          }>
                                          Submit
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
