import React, { useState } from 'react';
import Sidebar from "../../../components/Sidebar";
import Menu from "../../../components/menu.component";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Swal from 'sweetalert2';
import Col from 'react-bootstrap/Col';
import { useLocation, Link } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Offcanvas from 'react-bootstrap/Offcanvas';
import SubscriptionGroup from './SubscriptionGroup';
import Checkbox from "./checkbox";
import { RIFALY_USER_API, RIFALY_COOPERATE_API } from "../../../constants";
import Cookie from "universal-cookie";
import StaffEmptyState from '../Staff/StaffEmptyState';
const cookies = new Cookie();

export default function SubscriptionGroupDetail(props) {
    const location = useLocation();
    const state = location.state.item;

  return (
    <>
      <div className="row py-0">
        <div className="col-sm-2half order-1" id="sticky-sidebar">
          <div className="sticky-top">
            <Sidebar active="staff" />
          </div>
        </div>
        <div className="col order-2" id="main">
          <div className="container">
            <Menu />
            <div className="contents">
              <div className="row">
                <div className="col-md-12">
                  <div className="header mt-md-3">
                    <nav aria-label="breadcrumb mb-2">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item">
                                            <a href="/Overview" >
                                                Dashboard
                                            </a>
                                        </li>
                                        <li className="breadcrumb-item">
                                            <a href="/staff" >
                                                Corporates
                                            </a>
                                        </li>
                                        <li className="breadcrumb-item">
                                            <a href="/subscriptiongroup">
                                                Subscription Groups
                                            </a>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page">
                                            Subscription Group Details
                                        </li>
                                    </ol>
                                </nav>
                            </div>

                            <div className="content">
                                <div className="row mt-3">
                                    <div className="col-md-12">
                                        <div className="card pt-2 mt-2">
                                            <div className="px-4 pt-4">
                                                <div className="">
                                                    <h6 className="header-pretitle">Overview</h6>

                                                    <h1 className="header-title">
                                                        Subscription Group Details
                                                    </h1>
                                                </div>

                                                <hr />

                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <form>
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <div className="form-group">
                                                                        <label htmlFor="name" className="form-label">
                                                                            Group Name
                                                                        </label>
                                                                        <input
                                                                            type="text"
                                                                            disabled={true}
                                                                            className={"form-control"}
                                                                            id="name"
                                                                            defaultValue={state.name}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <div className="form-group">
                                                                        <label htmlFor="name" className="form-label">
                                                                            Company
                                                                        </label>
                                                                        <input type={"text"}
                                                                            className="form-control"
                                                                            id="companyId"
                                                                            name="companyId"
                                                                            disabled={true}
                                                                            defaultValue={state.company ? state.company.companyName : state.companyId}
                                                                        />
                                    
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-12 row">
                                                                    <div className="col-md-12">
                                                                        <label className="form-label">Selected Publications</label>
                                                                    </div>
                                                                    {state.papers && state.papers.length > 0 ?state.papers.map((issue, index) => {
                                                                        return (
                                                                            <div key={issue.id} className="col-md-4"  style={{ display: "flex", justifyContent: "left", alignItems: "left" }}>
                                                                                <Checkbox
                                                                                    id={issue.id}
                                                                                    type="checkbox"
                                                                                    name={issue.title}
                                                                                    onClick={() => {}}
                                                                                    checked={true}
                                                                                />
                                                                            </div>
                                                                        );
                                                                    }):"NO PUBLICATIONS SELECTED"}
                                                                </div>
                                                                <div className="col-md-12 row">
                                                                    <div className="col-md-12 p-3">
                                                                        <label className="form-label">Staff Present : {state.staffs.length} Members</label>
                                                                    </div>
                                                                    {state.staffs && state.staffs.length > 0 ?state.staffs.map((member, index) => {
                                                                        return (
                                                                            <div key={member.id} className="col-md-6"  style={{ display: "flex", justifyContent: "left", alignItems: "left" }}>
                                                                                <ul style={{listStyleType: "square"}}>
                                                                                    <li>{member.firstName+ " "+ member.lastName}: {member.email} {"("+ member.phone +")"}</li>
                                                                                </ul> 
                                                                            </div>
                                                                        );
                                                                    }):"THIS GROUP HAS NO MEMBERS."}
                                                                </div>
                                                            </div>

                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>  
          </div>           
        </div> 
      </div>
    </>
  );
}
