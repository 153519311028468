import React, { useState } from "react";
import logo from "../../public/images/R-logo.svg";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import { RIFALY_USER_API, RIFALY_COOPERATE_API } from "../../constants";
import Cookie from "universal-cookie";
const cookies = new Cookie();

export default function Login() {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [isLoading, setIsLoading] = useState(false);

  var navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    
      fetch(`${RIFALY_USER_API}auth/corporate/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email,
          password,
        }),
      })
        .then((data) => data.json())
        .then((response) => {
          if (response.status == 200) {
              setIsLoading(false);
              cookies.set("access-token", response.data.token);
              cookies.set("user_data", response.data);
              cookies.set("company_data", response.data.company);
              navigate("/Overview", { replace: true });
          } else {
            Swal.fire("Failed", response.error.message, "error");
            setIsLoading(false);
          }
        })
    .catch (err=>{
      console.log(err);
      setIsLoading(false);
      Swal.fire({
        icon: 'error',
        title: 'Login Failed',
        text: 'Check your internet connection, and please try again.',
        showConfirmButton: false,
        timer: 1500
      })
    })
  };

  return (
    <div className="row loginpage">
      <div className="col-6 login_left">
        <div className="Auth-form-container">
          <div className="login_left--contents">
            <div className="row login_left--logo  my-4">
              <img src={logo} className="R-logo" alt="Rifaly logo" />
            </div>
            <div className="row login_left--title">
                Rifaly  provides an easy way to read high-quality newspaper and magazine issues from cover to cover on the go!
            </div>
          </div>
        </div>
      </div>
      <div className="col-6 login_right">
        <div className="Auth-form-container">
          <div className="login_right--form">
            <form
              className="login_right--form--contents"
              onSubmit={handleSubmit}
            >
              <div className="login_right--form-title mt-2">
                <h5>
                  <span className="fw-normal mb-0">Hello Admin!</span>
                </h5>
              </div>

              <h6 className="login_right--form-titlewords fw-bold my-4 pb-3">
                Please login to your dashboard to continue
              </h6>

              <div className="login_right--form-input mt-3">
                <label>Email address</label>
                <input
                  type="email"
                  id="login_id"
                  className="form-control mt-1"
                  placeholder="Enter your Email"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="login_right--form-input mt-3">
                <label>Password</label>
                <input
                  type="password"
                  id="pword"
                  className="form-control mt-1"
                  placeholder="Enter password"
                  autoComplete="on"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <div className="login_right--form-input mt-3">
                <Button
                  type="submit"
                  className="login_right--btn-login"
                  disabled={isLoading}
                  value="Log In"
                >
                  {isLoading && (
                  <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      className="mr-2 text-white"
                      duration={3000}
                    />
                  )}
                  Log In
                </Button>
              </div>
              <p className="login_right--forgot-password mt-4">
                Forgot Password?{" "}
                <a href="/Forgot_Password"> click here to reset </a>
              </p>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
