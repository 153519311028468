import React, {useState} from 'react';
import Sidebar from '../../../components/Sidebar';
import Menu from '../../../components/menu.component';
import AddSubscriptionGroup from './AddSubscriptionGroup';

function EmptySubscriptionPage(props) {
    const [showedit, setshowedit]=useState(false);
    
    return (
        <>
            <div className="row py-0">
                <div className="col-sm-2half order-1" id="sticky-sidebar">
                    <div className="sticky-top">
                        <Sidebar active="staff" />
                    </div>
                </div>
                <div className="col order-2" id="main">
                <div  className='container'>
                    <Menu />
                        <div className='contents'>
                        <div className="row"> 
                            <div className="col-md-12">
                                <div className="header mt-md-3">
                                    <nav aria-label="breadcrumb mb-2">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item">
                                            <a href="/Overview">Dashboard</a>
                                            </li>
                                            <li className="breadcrumb-item active" aria-current="page">Subscription Groups</li>
                                        </ol>
                                    </nav>
                                    <div className="header d-flex justify-content-start mt-md-3">
                                        <div className="header-body">
                                            <h6 className="header-pretitle">Overview</h6>
                                            <h1 className="header-title">Corporate</h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className='col-md-12'>
                                <ul className="nav nav-tabs">
                                    <li className="nav-item">
                                        <a aria-current="page" className="nav-link" href="/staff">Staff's</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link active" href="/subscriptiongroups">Subscription Groups</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="content">
                        <div className="row my-4 d-flex justify-content-between">
                                <div className="col-md-4 m-0 pl-3">
                                    <div >
                                            <form role="search">
                                                <div className="input-group has-search">
                                                    <span className="bi bi-search form-control-feedback"></span>
                                                    <input type="text" className="form-control" name="x" placeholder="Search Employee..."/>
                                                </div>
                                            </form>
                                        </div>  
                                </div>
                                <div className="col-auto">
                                    <a className="btn btn-primary lift" href="/addsubscriptiongroup">
                                    <span><i className="bi bi-plus"></i></span>Add Corporate Subscriber Groups</a>
                                </div>
                        </div>
                        <div className="row mt-2 body-content">
                            <div className="col-md-12"><div>
                            <div className="page-empty">
                                <div className="card card-fill">
                                    <div className="card-body text-center">
                                    <svg width="68" height="68" viewBox="0 0 68 68" fill="none">
                                            <path d="M45.4963 55.0163L42.6488 59.2663C42.1661 59.9939 41.5109 
                                            60.5908 40.7415 61.0037C39.9721 61.4166 39.1125 61.6327 38.2394 
                                            61.6327C37.3662 61.6327 36.5066 61.4166 35.7373 61.0037C34.9679 
                                            60.5908 34.3127 59.9939 33.83 59.2663C30.855 54.6975 30.1112 53.1675 
                                            27.455 53.1675H14.875C13.7478 53.1675 12.6668 52.7197 11.8698 \
                                            51.9227C11.0728 51.1257 10.625 50.0447 10.625 48.9175V19.125C10.625 
                                            17.9978 11.0728 16.9168 11.8698 16.1198C12.6668 15.3228 13.7478 
                                            14.875 14.875 14.875H61.625C62.7522 14.875 63.8332 15.3228 64.6302 
                                            16.1198C65.4272 16.9168 65.875 17.9978 65.875 19.125V48.875C65.875 
                                            50.0022 65.4272 51.0832 64.6302 51.8802C63.8332 52.6772 62.7522 
                                            53.125 61.625 53.125C48.45 53.125 47.1112 52.5725 45.4963 55.0163Z" 
                                            fill="#D1D3D4"></path>
                                            <path d="M31.875 61.625C31.525 61.6232 31.1809 61.535 30.8732 
                                            61.3681C30.5655 61.2013 30.3037 60.9611 30.1112 60.6687L22.865 
                                            49.81C22.6701 49.5216 22.4073 49.2855 22.0998 49.1225C21.7922 
                                            48.9595 21.4493 48.8745 21.1012 48.875H8.5C6.80925 48.875 5.18774 
                                            48.2034 3.99219 47.0078C2.79665 45.8123 2.125 44.1908 2.125 
                                            42.5V12.75C2.125 11.0592 2.79665 9.43774 3.99219 8.24219C5.18774 
                                            7.04665 6.80925 6.375 8.5 6.375H55.25C56.9408 6.375 58.5623 7.04665 
                                            59.7578 8.24219C60.9534 9.43774 61.625 11.0592 61.625 12.75V42.5C61.625 
                                            44.1908 60.9534 45.8123 59.7578 47.0078C58.5623 48.2034 56.9408 48.875 
                                            55.25 48.875H51C50.4364 48.875 49.8959 48.6511 49.4974 48.2526C49.0989 
                                            47.8541 48.875 47.3136 48.875 46.75C48.875 46.1864 49.0989 45.6459 49.4974 
                                            45.2474C49.8959 44.8489 50.4364 44.625 51 44.625H55.25C55.8136 44.625 56.3541 
                                            44.4011 56.7526 44.0026C57.1511 43.6041 57.375 43.0636 57.375 42.5V12.75C57.375 
                                            12.1864 57.1511 11.6459 56.7526 11.2474C56.3541 10.8489 55.8136 10.625 55.25 
                                            10.625H8.5C7.93641 10.625 7.39591 10.8489 6.9974 11.2474C6.59888 11.6459 6.375 
                                            12.1864 6.375 12.75V42.5C6.375 43.0636 6.59888 43.6041 6.9974 44.0026C7.39591 44.4011 
                                            7.93641 44.625 8.5 44.625H21.1012C22.1529 44.6246 23.1884 44.8844 24.1153 45.3812C25.0422 
                                            45.8781 25.8318 46.5965 26.4137 47.4725L31.875 55.675L38.6113 45.5813C38.9378 45.1472 
                                            39.4181 44.8546 39.9536 44.7636C40.4891 44.6726 41.0392 44.7902 41.4907 45.0921C41.9422 
                                            45.394 42.261 45.8574 42.3815 46.3871C42.5021 46.9167 42.4152 47.4724 42.1387 
                                            47.94L33.6388 60.69C33.4438 60.9784 33.181 61.2145 32.8735 61.3775C32.566 61.5405 
                                            32.2231 61.6255 31.875 61.625Z" fill="#414955"></path>
                                            <path d="M23.375 25.5C22.5344 25.5 21.7127 25.2507 21.0138 24.7837C20.3149 24.3168 19.7702 
                                            23.653 19.4485 22.8764C19.1268 22.0998 19.0427 21.2453 19.2067 20.4209C19.3707 19.5964 
                                            19.7754 18.8392 20.3698 18.2448C20.9642 17.6504 21.7214 17.2457 22.5459 17.0817C23.3703 
                                            16.9177 24.2248 17.0018 25.0014 17.3235C25.778 17.6452 26.4418 18.1899 26.9087 18.8888C27.3757 
                                            19.5877 27.625 20.4094 27.625 21.25C27.625 22.3772 27.1772 23.4582 26.3802 24.2552C25.5832 
                                            25.0522 24.5022 25.5 23.375 25.5Z" fill="#414955"></path>
                                            <path d="M40.375 25.5C39.5344 25.5 38.7127 25.2507 38.0138 24.7837C37.3149 24.3168 36.7702 
                                            23.653 36.4485 22.8764C36.1268 22.0998 36.0427 21.2453 36.2067 20.4209C36.3707 19.5964 
                                            36.7754 18.8392 37.3698 18.2448C37.9642 17.6504 38.7214 17.2457 39.5459 17.0817C40.3703 
                                            16.9177 41.2248 17.0018 42.0014 17.3235C42.778 17.6452 43.4418 18.1899 43.9087 18.8888C44.3757 
                                            19.5877 44.625 20.4094 44.625 21.25C44.625 22.3772 44.1772 23.4582 43.3802 24.2552C42.5832 
                                            25.0522 41.5022 25.5 40.375 25.5Z" fill="#414955"></path>
                                        </svg>
                                        <h3 className="mt-4">No Corporate Subscriber Groups</h3>
                                        <p className="text-muted m-4">There are no subscription groups and their respective information for your corporate account currently available on this page.</p>
                                            <a className="btn btn-primary lift" href="/addsubscriptiongroup">
                                                <span><i className="bi bi-plus"></i>
                                                </span>Add Corporate Subscriber Groups</a>
                                    </div>
                                </div>
                            </div>
                            </div>
                            </div>
                        </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
    </>         

    );
}

export default EmptySubscriptionPage;