import React, { useState, useEffect } from 'react';
import Checkbox from './checkbox';
import { RIFALY_PUBLISHER_API, RIFALY_COOPERATE_API } from '../../../constants';
import Cookie from 'universal-cookie';
const cookies = new Cookie();

export default function Application(props) {
  const [list, setList] = useState([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState();

  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);

  useEffect(() => {
    fetch(`${RIFALY_PUBLISHER_API}issues`)
      .then((response) => response.json())
      .then((actualData) => {
        let myPapers = [];
        if (props.isEdit) {
          myPapers = props.papers;
        }
        let filteredCategory = actualData.data.filter(
          (Onlynewspapers) => Onlynewspapers.categoryId === 1,
        );
        if (myPapers.length > 0) {
          let joinedPapers = filteredCategory.map((issue) => {
            let mypaper = myPapers.find((paper) => paper.id == issue.id);
            let item = issue;
            item.isSelected = mypaper ? true : false;
            return item;
          });

          setIsCheck(joinedPapers.map((paper) => paper.isSelected));
        } else {
          setIsCheck(new Array(filteredCategory.length).fill(false));
        }
        setList(filteredCategory);
        setError(null);
        // setList(filteredCategory);
        // setError(null);
        // setIsCheck(new Array(filteredCategory.length).fill(false));
      })
      .catch((err) => {
        setError(err.message);
        setList([]);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handleSelectAll = (e) => {
    setIsCheckAll(!isCheckAll);
    let vals = list.map((li) => {
      return { id: li.id, title: li.title };
    });

    // let x = vals;
    setIsCheck(new Array(list.length).fill(true));
    if (!e.target.checked) {
      setIsCheck(new Array(list.length).fill(false));
      vals = [];
    }
    save(vals);
  };

  const handleClick = (index) => {
    let mychecks = isCheck.map((c, i) => (i === index ? !c : c));

    setIsCheck(mychecks);
    let x = mychecks
      .map((y, z) => (y ? z : -1))
      .filter((y) => y >= 0)
      .map((p) => {
        let m = list.find((l, i) => i === p);
        return { id: m.id, title: m.title };
      });
    save(x);
  };

  const save = (selection) => {
    props.onSave(selection);
  };
  const catalog = list.map((li, i) => {
    return (
      <div
        key={li.id}
        className='issues d-flex col-md-3 flex-fill'>
        <Checkbox
          className='checkbox'
          key={li.id}
          type='checkbox'
          name={li.title}
          id={li.id}
          checked={isCheck[i]}
          onClick={handleClick}
        />
      </div>
    );
  });

  return (
    <div className='Application'>
      <div>
        {loading && <div>A moment please...</div>}
        {error && (
          <div>{`There is a problem fetching the post data - ${error}`}</div>
        )}
        <div className='col-md-12 d-flex'>
          <div className='col-md-6'>
            <div className='form-group'>
              <label
                htmlFor='firstName'
                className='form-label'
                style={{ color: '#0F1F26', fontWeight: 400, fontSize: '14px' }}>
                Select Papers
              </label>
            </div>
          </div>
          <div
            className='col-md-6 d-flex'
            style={{ justifyContent: 'flex-end' }}>
            <div className='selectAll'>
              {/* <Checkbox
                                    type="checkbox"
                                    name="Select All"
                                    id="selectAll"
                                    className="checkbox"
                                    handleClick={(e)=>handleSelectAll(e)}    
                                /> */}

              <Checkbox
                type='checkbox'
                name='selectAll'
                id='selectAll'
                onClick={handleSelectAll}
                isChecked={isCheckAll}
              />
            </div>
          </div>
        </div>
        <div className='d-flex flex-wrap allissues col-md-12'>
          {list.map((li, i) => {
            return (
              <div
                key={li.id}
                className='issues d-flex col-md-4 flex-fill'>
                <Checkbox
                  className='checkbox'
                  key={li.id}
                  type='checkbox'
                  name={li.title}
                  id={li.id}
                  checked={isCheck[i]}
                  onClick={() => handleClick(i)}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
