import React, { useState } from 'react';
import Sidebar from "../../components/Sidebar";
import Menu from "../../components/menu.component";
import "rsuite/dist/rsuite.min.css";
import { useLocation, Link } from "react-router-dom";
import moment from "moment";
import "moment-timezone";
import logo from './logo.svg'

export default function Profile(props) {
    const location = useLocation();
    const state = location.state;

  return (
    <>
      <div className="row py-0">
        <div className="col-sm-2half order-1" id="sticky-sidebar">
          <div className="sticky-top">
            <Sidebar active="overview" />
          </div>
        </div>
        <div className="col order-2" id="main">
          <div className="container">
            <Menu />
            <div className="contents">
              <div className="row">
                <div className="col-md-12">
                    <div className="header mt-md-3">
                        <nav aria-label="breadcrumb mb-2">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                <a href="/Overview">Dashboard</a>
                                </li>
                                <li className="breadcrumb-item active">
                                <a href="/Profile">Profile</a>
                                </li>
                            </ol>
                        </nav>
                    </div>

                            <div className="content">
                                <div className="row mt-3">
                                    <div className="col-md-12">
                                    <div className="card pt-2 mt-2">
                                        <div className="px-4 pt-4">
                                        <div className="">
                                            <h6 className="header-pretitle">Overview</h6>

                                            <h1 className="header-title">Admin Details</h1>
                                        </div>

                                        <hr />

                                        <div className="row">
                                            <div className="col-md-12">
                                            <form >
                                                <div className="row">
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                    <label
                                                        htmlFor="adminImage"
                                                        className="form-label"
                                                    >
                                                        Profile Picture
                                                    </label>
                                                    <img
                                                        src={state.user.avatar != 'avatar.png' ?state.user.avatar:logo}
                                                        alt=""
                                                        className="AdminroundedCircle"
                                                        style={{ width: "112px" }}
                                                    />
                                                    </div>
                                                </div>
                                                </div>
                                                <div className="row">
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                    <label
                                                        htmlFor="firstName"
                                                        className="form-label"
                                                    >
                                                        First Name
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className={"form-control"}
                                                        id="firstName"
                                                        disabled={true}
                                                        placeholder="Enter First name"
                                                        value={state.user.first_name?state.user.first_name:" "}
                                                    />
                                                    </div>
                                                </div>

                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                    <label
                                                        htmlFor="lastName"
                                                        className="form-label"
                                                    >
                                                        Last Name
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className={"form-control"}
                                                        id="lastName"
                                                        disabled={true}
                                                        placeholder="Enter Last name"
                                                        value={state.user.last_name?state.user.last_name:" "}
                                                    />
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                    <label
                                                        htmlFor="Address"
                                                        className="form-label"
                                                    >
                                                        Company Address
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className={"form-control"}
                                                        id="address"
                                                        disabled={true}
                                                        placeholder="Enter Address"
                                                        value={(state.company.city?state.company.city:" " )+ " " + (state.company.address?state.company.address:" ")}
                                                    />
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                    <label
                                                        htmlFor="company"
                                                        className="form-label"
                                                    >
                                                        Company Name
                                                    </label>
                                                    <input
                                                        disabled={true}
                                                        value={state.company.companyName?state.company.companyName:" "}
                                                        className="form-control"
                                                    />
                                                    
                                                    </div>
                                                </div>

                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                    <label
                                                        htmlFor="firstName"
                                                        className="form-label"
                                                    >
                                                        Email Address
                                                    </label>
                                                    <input
                                                        type="email"
                                                        className={"form-control"}
                                                        id="email"
                                                        disabled={true}
                                                        placeholder="Enter Email Address"
                                                        value={state.user.email?state.user.email:" "}
                                                    />
                                                    </div>
                                                </div>

                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                    <label htmlFor="phone" className="form-label">
                                                        Phone Number
                                                    </label>
                                                    <input
                                                        type="text"
                                                        disabled={true}
                                                        className={"form-control"}
                                                        id="phone"
                                                        value={state.user.phone?state.user.phone:" "}
                                                    />
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                    <label htmlFor="status" className="form-label">
                                                        Status
                                                    </label>
                                                    <input
                                                        type="text"
                                                        disabled={true}
                                                        className={"form-control"}
                                                        id="status"
                                                        value={state.company.status?state.company.status:" "}
                                                    />
                                                    </div>
                                                </div>

                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                    <label htmlFor="role" className="form-label">
                                                        Role
                                                    </label>
                                                    <input disabled={true} className="form-control" 
                                                        value={state.user.role == 5?"Admin":"User"}
                                                        />
                                                        
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                    <label
                                                        htmlFor="createdDate"
                                                        className="form-label"
                                                    >
                                                        Created On
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className={"form-control"}
                                                        id="createdDate"
                                                        disabled={true}
                                                        placeholder="Enter Date Created"
                                                        value={moment(state.company.createdAt?state.company.createdAt:" ").format(
                                                            "DD/MM/YYYY HH:MM:SS"
                                                          )}
                                                    />
                                                    </div>
                                                </div>
                                                </div>
                                            </form>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>  
          </div>           
        </div> 
      </div>
    </>
  );
}
