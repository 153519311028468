import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Swal from 'sweetalert2';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { RIFALY_USER_API, RIFALY_COOPERATE_API } from "../../../constants";
import Cookie from "universal-cookie";
const cookies = new Cookie();

export default function EditStaff(props) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const [APIData, setAPIData] = useState([])

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const userdata = props.data;

  const [editStaffObject,seteditStaffObject] = useState({
    firstName: userdata.firstName,
    lastName: userdata.lastName,
    phone: userdata.phone,
    role: "user",
    email: userdata.email,
    status: userdata.status,
    subscriptionGroupId: userdata.subscriptionGroupId,
    id: userdata.id,
    companyId: userdata.companyId,
  });
  const [message, setMessage] = useState("");
  const [companySubscriptionGroups, setCompanySubscriptionGroups] = useState(
    []
  );
  const [inputFields, setInputFields] = useState([]);

  const addInputField = () => {
    setInputFields([...inputFields, editStaffObject]);
  };
  const removeInputFields = (index) => {
    const rows = [...inputFields];
    rows.splice(index, 1);
    setInputFields(rows);
  };

  const handleChange = (e) => {
    e.preventDefault()
    let name = e.target.name;
    let value = e.target.value.trim();
    let data = editStaffObject;
    data[ name ] = value;
    seteditStaffObject(data);

  };

  const handleStaffInformationUpdate = (e) => {
    e.preventDefault();
    const accessToken = cookies.get("access-token");
    editStaffObject.subscriptionGroup = editStaffObject.subscriptionGroupId;
    if(editStaffObject.companyId == cookies.get("company_data").id){
      fetch(`${RIFALY_COOPERATE_API}staffs/${userdata.id}`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(editStaffObject),
      }).then((response) => {
        if (response.status === 200) {
          setMessage("Staff edited successfully");
          props.onComplete();
          handleClose();

        } else {
          toast.error('Error Notification !', {
            position: toast.POSITION.TOP_LEFT
          });
        }
      });
    }
    
  };
  const showToastMessage = () => {
    toast.success('Subscription Group Created !', {
        position: toast.POSITION.TOP_RIGHT
    });
  };

useEffect(() => {
  loadSubscriptionGroupData();
}, []);

const loadSubscriptionGroupData =()=>{
  try {
    fetch(
      `${RIFALY_COOPERATE_API}subscription-groups/company/${
        cookies.get("company_data").id
      }`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + cookies.get("access-token"),
        },
      }
    )
      .then((data) => data.json())
      .then((result) => {
        setCompanySubscriptionGroups(result.data);
        if(result.data.length >0){
          let obj = editStaffObject;
          obj.subscriptionGroupId = result.data[0].id;
          seteditStaffObject(obj);
        }
      });
  } catch (err) {
    console.log(err);
  }
}
const handledeleteStaff = (e, SDID) => {
  e.preventDefault();
  fetch(`${RIFALY_COOPERATE_API}staffs/update-status/${SDID}`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + cookies.get("access-token"),
    },
    body: JSON.stringify({status: "deleted"}),
  }).then((res) => {
    if (res.status === 200) {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger'
        },
        buttonsStyling: false
      })
      swalWithBootstrapButtons.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, cancel!',
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          setMessage("Staff successfully deleted");
          swalWithBootstrapButtons.fire(
            'Deleted!',
            'Your Staff has been deleted.',
            'success'
          )
          props.onComplete();
          handleClose();
          } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire(
            'Cancelled',
            'The Staff is not deleted :)',
            'error'
          )
        }
      })
    } else {
      toast.error(res.message, {
        position: toast.POSITION.TOP_LEFT
      });
    }
  });
};

  return (
    <>
      <ToastContainer />
      <div>
          <div className="me-2 text-success" onClick={handleShow}>
            Edit
          </div>
          <Offcanvas show={show} onHide={handleClose} {...props} backdrop="static">
            <Offcanvas.Header closeButton>
              Edit
              <Offcanvas.Title>
                Edit Staff
              </Offcanvas.Title>
            <hr />
            </Offcanvas.Header>
            <Offcanvas.Body className="show-grid">
              <Container>
                <Form>
                  <Row>
                    <Col xs={12} md={6}>
                      <Form.Group className="mb-3" controlId="editStaff.firstNameInput">
                        <Form.Label>First Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Text Here"
                          name="firstName"
                          autoFocus
                          defaultValue={editStaffObject.firstName} 
                          onChange={handleChange}
                          required
                      />
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                    <Form.Group className="mb-3" controlId="editStaff.lastNameInput">
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Text Here"
                          name="lastName"
                          autoFocus
                          defaultValue={editStaffObject.lastName} 
                          onChange={handleChange}
                          required
                      /> 
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={6}>
                      <Form.Group className="mb-3" controlId="editStaff.EmailInput">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Text Here"
                          name="email"
                          autoFocus
                          defaultValue={editStaffObject.email} 
                          onChange={handleChange}
                          required
                      />
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                      <Form.Group
                        className="mb-3"
                        controlId="editStaff.SubscriptionInput"
                      >
                        <Form.Label>Subscription Group</Form.Label>
                        <Form.Select aria-label="select subscription" name="subscriptionGroupId" required defaultValue={editStaffObject.subscriptionGroupId}
                                      onChange={(e) => handleChange(e)}>                                            
                                                      {companySubscriptionGroups.map(
                                                        (companySubscriptionGroup) => (
                                                          <option
                                                            value={
                                                              companySubscriptionGroup.id
                                                            }
                                                            key={
                                                              companySubscriptionGroup.id
                                                            }
                                                          >
                                                            {
                                                              companySubscriptionGroup.name
                                                            }
                                                          </option>
                                                        )
                                                      )}
                        </Form.Select>  
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={6}>
                    <Form.Group
                        className="mb-3"
                        controlId="editStaff.StatusInput"
                      >
                        <Form.Label>Account Status</Form.Label>
                        <Form.Select aria-label="select Status" name="status" required defaultValue={editStaffObject.status.toLowerCase()} onChange={handleChange}>
                          <option value="active">Active</option>
                          <option value="suspended">Suspended</option>
                      </Form.Select> 
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                    <Form.Group className="mb-3" controlId="editStaff.lastNameInput">
                        <Form.Label>Phone Number</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Phone No."
                          name="phone"
                          autoFocus
                          defaultValue={editStaffObject.phone} 
                          onChange={handleChange}
                          required
                      /> 
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={6}>
                        <Button  onClick={(e) => handledeleteStaff(e, parseInt(editStaffObject.id))}>
                            Delete Account
                        </Button>
                    </Col>
                    <Col xs={12} md={6}>
                        <Button onClick={(e) => handleStaffInformationUpdate(e)}>
                            Update Staff
                        </Button>
                    </Col>
                  </Row>
                </Form>            
              </Container>
            </Offcanvas.Body>
          </Offcanvas>
      </div>
    </>
  );
}
