import React, { useState } from 'react';
import Sidebar from "../../../components/Sidebar";
import Menu from "../../../components/menu.component";
import Table from "react-bootstrap/Table";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownMenu from "react-bootstrap/DropdownMenu";
import "rsuite/dist/rsuite.min.css";
import { DateRangePicker } from "rsuite";
import { useLocation, Link } from "react-router-dom";
import CircleLoader from "react-spinners/CircleLoader";
import { useNavigate } from "react-router-dom";
import Checkbox from "../SubscriptionGroups/checkbox";
import axios from "axios";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import { RIFALY_USER_API, RIFALY_COOPERATE_API } from "../../../constants";
import Cookie from "universal-cookie";
const cookies = new Cookie();


export default function StaffDetails(props) {
    const location = useLocation();
    const value = location.state.info;

  return (
    <>
      <div className="row py-0">
        <div className="col-sm-2half order-1" id="sticky-sidebar">
          <div className="sticky-top">
            <Sidebar active="staff" />
          </div>
        </div>
        <div className="col order-2" id="main">
          <div className="container">
            <Menu />
            <div className="contents">
              <div className="row">
                <div className="col-md-12">
                    <div className="header mt-md-3">
                        <nav aria-label="breadcrumb mb-2">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                <a href="/Overview">Dashboard</a>
                                </li>
                                <li className="breadcrumb-item">
                                <a href="/staff">Corporate</a>
                                </li>
                                <li
                                className="breadcrumb-item"
                                aria-current="page"
                                >
                                Staff
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                Staff Details
                                </li>
                            </ol>
                        </nav>
                    </div>

                            <div className="content">
                                <div className="row mt-3">
                                    <div className="col-md-12">
                                    <div className="card pt-2 mt-2">
                                        <div className="px-4 pt-4">
                                        <div className="">
                                            <h6 className="header-pretitle">Overview</h6>

                                            <h1 className="header-title">Staff Details</h1>
                                        </div>

                                        <hr />

                                        <div className="row">
                                            <div className="col-md-12">
                                            <form >
                                                <div className="row">
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                    <label
                                                        htmlFor="firstName"
                                                        className="form-label"
                                                    >
                                                        First Name
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className={"form-control"}
                                                        id="firstName"
                                                        disabled={true}
                                                        placeholder="Enter First name"
                                                        defaultValue={value.firstName}
                                                    />
                                                    </div>
                                                </div>

                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                    <label
                                                        htmlFor="lastName"
                                                        className="form-label"
                                                    >
                                                        Last Name
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className={"form-control"}
                                                        id="lastName"
                                                        disabled={true}
                                                        placeholder="Enter Last name"
                                                        defaultValue={value.lastName}
                                                    />
                                                    </div>
                                                </div>

                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                    <label
                                                        htmlFor="company"
                                                        className="form-label"
                                                    >
                                                        Company
                                                    </label>
                                                    <input
                                                        disabled={true}
                                                        defaultValue={value.company?value.company.companyName:" "}
                                                        className="form-control"
                                                    />
                                                    
                                                    </div>
                                                </div>

                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                    <label
                                                        htmlFor="firstName"
                                                        className="form-label"
                                                    >
                                                        Email Address
                                                    </label>
                                                    <input
                                                        type="email"
                                                        className={"form-control"}
                                                        id="email"
                                                        disabled={true}
                                                        placeholder="Enter Email Address"
                                                        defaultValue={value.email}
                                                    />
                                                    </div>
                                                </div>

                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                    <label htmlFor="phone" className="form-label">
                                                        Phone Number
                                                    </label>
                                                    <input
                                                        type="text"
                                                        disabled={true}
                                                        className={"form-control"}
                                                        id="phoner"
                                                        defaultValue={value.phone}
                                                    />
                                                    </div>
                                                </div>

                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                    <label htmlFor="role" className="form-label">
                                                        Role
                                                    </label>
                                                    <input disabled={true} className="form-control" defaultValue={value.role.toUpperCase()}/>
                                                        
                                                    </div>
                                                </div>
                                                <div className="col-md-4" id="sg-holder">
                                                    <div className="form-group">
                                                    <label htmlFor="role" className="form-label">
                                                        Subscription Group
                                                    </label>
                                                    <input disabled={true} className="form-control" defaultValue={value.subscription_group ? value.subscription_group.name : " "}/>
                                                        
                                                    </div>
                                                </div>
                                                </div>
                    <div className="col-md-12 row">
                                                    <div className="col-md-12">
                                                    <label className="form-label">Allowed Publications</label>
                                                    </div>
                                                    {value.subscription_group && value.subscription_group.papers.length > 0 ? value.subscription_group.papers.map((issue, index) => {
                                                    return (
                                                        <div key={issue.id} className="col-md-4"  style={{ display: "flex", justifyContent: "left", alignItems: "left" }}>
                                                        <Checkbox
                                                            type="checkbox"
                                                            id={issue.id}
                                                            name={issue.title}
                                                            onClick={()=>{}}
                                                            checked={true}
                                                        />
                                                        </div>
                                                    );
                                                    }):"No Publications allowed"}
                                                </div>
                                                
                                            </form>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>  
          </div>           
        </div> 
      </div>
    </>
  );
}
