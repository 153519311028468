import React from "react";

// const Checkbox = ({ id, name, type, handleClick }) => {
const Checkbox = ({ id, type, name, onClick, checked }) => {
  const handleChange = (e) => {
    // alert("t: " + e.target.checked);
    onClick(e);
  };
  return (
    <div className="legend">
      <input
        id={id}
        name={name}
        type={type}
        onChange={handleChange}
        className="checkbox"
        checked={checked}
      />
      <label htmlFor="name">{name}</label>
    </div>
  );
};

export default Checkbox;
