import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "../public/images/R-logo.svg";
import DIcon1 from "../public/images/Chart.svg";
import DIcon2 from "../public/images/Dstaff.svg";
import DIcon3 from "../public/images/Dbill.svg";
import DIcon4 from "../public/images/GreenChart.svg";
import DIcon5 from "../public/images/Vector.svg";
import DIcon6 from "../public/images/2 User.svg";

const Sidebar = (props) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [activeItem, setActiveItem] = useState(props.active.toLowerCase());
  const [stepHeight, setStepHeight] = useState(0);
  const sidebarRef = useRef();
  const indicatorRef = useRef();
  const location = useLocation();

  const sidebarNavItems = [
    {
      display: "Overview",
      icon: (
        <span>
          <img
            src={activeItem=="overview" ? DIcon3 : DIcon5}
            className="Icon"
            alt="Rifaly Icon"
          />
        </span>
      ),
      to: "/Overview",
      section: "overview",
    },
    {
      display: "Corporate",
      icon: (
        <span>
          <img
            src={activeItem=="staff" ? DIcon6 : DIcon2}
            className="Icon"
            alt="Rifaly Icon"
          />
        </span>
      ),
      to: "/staff",
      section: "staff",
    },
    {
      display: "Reports",
      icon: (
        <span>
          <img
            src={activeItem=="reports" ? DIcon4 : DIcon1}
            className="Icon"
            alt="Rifaly Icon"
          />
        </span>
      ),
      to: "/usagereport",
      section: "reports",
    },
  ];

  useEffect(() => {
    setTimeout(() => {
    }, 50);
  }, []);

  useEffect(() => {
    const curPath = window.location.pathname.split("/")[1];

    setActiveItem(props.active.toLowerCase());

    sidebarNavItems.forEach((navItem, index) => {
      if (navItem.section.toLowerCase() === activeItem) setActiveIndex(index);
    });
  }, [location]);
  return (
    <div
      className="navbar navbar-vertical flex-column navbar-expand-md"
      id="sidebar"
    >
      <div className="">
        <div className="sidebar__logo">
          <img src={logo} className="Rsidebar__logo" alt="Rifaly logo" />
        </div>
        <div ref={sidebarRef} className="sidebar__menu mt-4">
          <div
            ref={indicatorRef}
            className="sidebar__menu__indicator"
            style={{
              transform: `translateX(-50%) translateY(${
                activeIndex * stepHeight
              }px)`,
            }}
          ></div>
          {sidebarNavItems.map((item, index) => (
            <Link to={item.to} key={index}>
              <div
                className={`sidebar__menu__item ${
                  activeItem === item.section ? "active" : ""
                }`}
              >
                <div className="sidebar__menu__item__icon">{item.icon}</div>
                <div className="sidebar__menu__item__text">{item.display}</div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
