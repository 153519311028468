import React, { useState, useEffect } from "react";
import DIcon4 from "./logo.svg";
import { Navbar, Nav, Container, NavDropdown } from "react-bootstrap";
import { useNavigate, Link } from "react-router-dom";
import Cookie from "universal-cookie";

const cookies = new Cookie();

const Menu = () => {
  var localStorage = window.localStorage;
  const items = JSON.parse(localStorage.getItem("user_data"));
  var navigate = useNavigate();

  const user = cookies.get("user_data");
  const company = cookies.get("company_data");

  const handleLogout = () => {
    localStorage.clear();
    navigate("/", { replace: false });
  };
  const UserMenu = (
    <div className="row">
      <div className="col">
        <p style={{ margin: "0px", color: "#A5A5A5" }}>{user.first_name}</p>
        <p style={{ margin: "0px", color: "#313131" }}>{company.companyName}</p>
      </div>
      <div className="col">
        <img
          src={user.avatar != 'avatar.png' ? user.avatar:DIcon4}
          alt=""
          className="roundedCircle"
          style={{ width: "40px" }}
        />
      </div>
    </div>
  );
  return (
    <Navbar
      collapseOnSelect
      fixed="top"
      expand="sm"
      bg="light"
      variant="light"
      id="topnav"
    >
      <Container fluid>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mx-auto">
            <Nav.Link href="#">Resources</Nav.Link>
            <Nav.Link href="#">Support</Nav.Link>
            <Link  to={"/Profile"} state={{user, company}} className="update-link_prop">Account</Link>
          </Nav>
          <Nav>
            <Nav.Link href="#">
              <i className="bi bi-gear"></i>
            </Nav.Link>
            <Nav.Link href="#">
              <span className="bi bi-bell grey-color"> </span>
            </Nav.Link>
            <NavDropdown title={UserMenu} id="collasible-nav-dropdown">
              <Link className="edit-link" to={"/Profile"} state={{user, company}}>Pofile</Link>
              {/* <NavDropdown.Item href="#">Settings</NavDropdown.Item> */}
              <NavDropdown.Divider />
              <NavDropdown.Item onClick={handleLogout}>Logout</NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Menu;
