import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import "./css/custom.css";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import Login from "./pages/Authentication/Sign-In.js";
import ResetPassword from "./pages/Authentication/Reset_Password";
import EmailresetPassword from "./pages/Authentication/Forgot_Password";
import CreatePassword from "./pages/CreatePassword/createpassword";

import MainDashboard from "./pages/Overview/DashboardOverview";
import EmptyOverviewPage from "./pages/Overview/EmptyOverviewPage";
import StaffEmptyState from "./pages/Corporate/Staff/StaffEmptyState";
import AddStaff from "./pages/Corporate/Staff/AddStaff";
import EditStaff from "./pages/Corporate/Staff/EditStaff";
import StaffDetails from "./pages/Corporate/Staff/StaffDetails";
import MainStaff from "./pages/Corporate/Staff/Staff";
import SummaryReports from "./pages/Reports/SummaryReports";
import UsageReport from "./pages/Reports/UsageReport";
import EmptySubscriptionPage from "./pages/Corporate/SubscriptionGroups/EmptySubscriptionGroup";
import SubscriptionGroup from "./pages/Corporate/SubscriptionGroups/SubscriptionGroup";
import AddSubscriptionGroup from "./pages/Corporate/SubscriptionGroups/AddSubscriptionGroup";
import SubscriptionGroupDetail from "./pages/Corporate/SubscriptionGroups/SubscriptionGroupDetail";
import EditSubscriptionGroup from "./pages/Corporate/SubscriptionGroups/EditSubscriptionGroup";
import Profile from "./pages/Profile/Profile";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/Reset_Password" element={<ResetPassword />} />
          <Route path="/Forgot_Password" element={<EmailresetPassword />} />
          <Route path="/Create_password" element={<CreatePassword />} />

          <Route path="/Overview" element={<MainDashboard />} />
          <Route path="/staff" element={<MainStaff />} />
          <Route path="/addstaff" element={<AddStaff />} />
          <Route path="/editstaff" element={<EditStaff />} />
          <Route path="/staffDetail" element={<StaffDetails />} />
          <Route path="/nostaff" element={<StaffEmptyState />} />
          <Route path="/usagereport" element={<UsageReport />} />
          <Route path="/summaryreports" element={<SummaryReports />} />
          <Route path="/nosubscription" element={<EmptySubscriptionPage />} />
          <Route path="/subscriptiongroup" element={<SubscriptionGroup />} />
          <Route path="/addsubscriptiongroup" element={<AddSubscriptionGroup />} />
          <Route path="/subscriptionGroupDetail" element={<SubscriptionGroupDetail />} />
          <Route path="/EditsubscriptionGroup" element={<EditSubscriptionGroup />} />
          <Route path="/profile" element={<Profile />} />

        </Routes>
      </Router>
    </div>
  );
}
export default App;
