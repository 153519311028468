import React, { useState, useEffect } from "react";
import Sidebar from "../../../components/Sidebar";
import Menu from "../../../components/menu.component";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Papa from "papaparse";
import MainStaff from "./Staff";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Swal from "sweetalert2";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { RIFALY_COOPERATE_API } from "../../../constants";
import Cookie from "universal-cookie";
const cookies = new Cookie();

function AddStaff() {
  const newStaffEmptyObject = {
    firstName: "",
    lastName: "",
    phone: "",
    role: "user",
    email: "",
    subscriptionGroup: "",
  };
  const [message, setMessage] = useState("");
  const [companySubscriptionGroups, setCompanySubscriptionGroups] = useState(
    [],
  );
  const [inputFields, setInputFields] = useState([newStaffEmptyObject]);
  var navigate = useNavigate();

  const addInputField = () => {
    setInputFields([...inputFields, newStaffEmptyObject]);
  };
  const removeInputFields = (index) => {
    const rows = [...inputFields];
    rows.splice(index, 1);
    setInputFields(rows);
  };

  const handleChange = (index, event) => {
    const { name, value } = event.target;
    const list = [...inputFields];
    list[index][name] = value;
    setInputFields(list);
  };

  let handleSubmit = (e) => {
    e.preventDefault();
    const accessToken = cookies.get("access-token");
    const submitData = inputFields.map((singleInputField) => {
      return {
        firstName:singleInputField.firstName.trim(),
        lastName:singleInputField.lastName.trim(),
        phone:singleInputField.phone.trim(),
        email:singleInputField.email.trim(),
        subscriptionGroup:singleInputField.subscriptionGroup,
        companyId: cookies.get("company_data").id,
        role: parseInt(singleInputField.role),
      };
    });
    fetch(`${RIFALY_COOPERATE_API}staffs`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(submitData[0]),
    }).then((response) => {
      if (response.status === 201) {
        toast.success("Staff created successfully", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setMessage("Staff created successfully");
        navigate("/staff", { replace: true });
      } else {
        toast.error(response.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };

  const [parsedCsvData, setParsedCsvData] = useState([]);

  const handleSubmitCSV = (e) => {
    e.preventDefault();
    let uploadCSV = document.getElementById("csvFileInput");
    uploadCSV.click();
    uploadCSV.addEventListener("change", (e) => {
      Papa.parse(e.target.files[0], {
        header: true,
        skipEmptyLines: true,
        complete: (results) => {
          setParsedCsvData(results.data);
          if (results.data.length > 0) {
            const swalWithBootstrapButtons = Swal.mixin({
              customClass: {
                confirmButton: "btn btn-success",
                cancelButton: "btn btn-danger",
              },
              buttonsStyling: false,
            });
            swalWithBootstrapButtons
              .fire({
                title:
                  "Upload this file? with" +
                  " " +
                  results.data.length +
                  " " +
                  "data",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Yes, upload file!",
                cancelButtonText: "No, cancel!",
                reverseButtons: true,
              })
              .then((result) => {
                if (result.isConfirmed) {
                  handleSubmission(results.data);
                } else if (
                  /* Read more about handling dismissals below */
                  result.dismiss === Swal.DismissReason.cancel
                ) {
                  swalWithBootstrapButtons.fire(
                    "Cancelled",
                    "Your File Upload is Cancelled :)",
                    "error",
                  );
                }
              });
          } else {
            toast.error("csv failed to upload", {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        },
      });
    });
  };
  const handleSubmission = (data) => {
    const accessToken = cookies.get("access-token");
    data = data
      .map((d) => {
        d.companyId = cookies.get("company_data").id;
        
        let sg = companySubscriptionGroups.filter(
          (g) => {
            return g.name.toLowerCase() === d.subscription_group.toLowerCase()
          }
        );        
        d.subscriptionGroupId = sg && sg.length > 0 ? sg[0].id : null;
        return d;
      })
      .filter((d) => d.subscriptionGroupId != null);
    var body = { data: data };
   
    fetch(`${RIFALY_COOPERATE_API}staffs/bulk`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === 201) {
          const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
              confirmButton: "btn btn-success",
              cancelButton: "btn btn-danger",
            },
            buttonsStyling: false,
          });
          swalWithBootstrapButtons.fire(
            "Uploaded!",
            "Your file has been uploaded.",
            "success",
          );
          navigate("/staff", { replace: true });
        } else {
          toast.error(response.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((e) => {
        console.log("error", e);
        toast.error(e.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  useEffect(() => {
    try {
      fetch(
        `${RIFALY_COOPERATE_API}subscription-groups/company/${
          cookies.get("company_data").id
        }`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + cookies.get("access-token"),
          },
        },
      )
        .then((data) => data.json())
        .then((result) => {
          setCompanySubscriptionGroups(result.data);
          inputFields[0]["subscriptionGroup"] = result.data.length
            ? result.data[0].id
            : null;
          setInputFields(inputFields);
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  const showToastMessage = () => {
    toast.success("Staff Created !", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };
  return (
    <>
      <ToastContainer />
      <div className='row py-0'>
        <div
          className='col-sm-2half order-1'
          id='sticky-sidebar'>
          <div className='sticky-top'>
            <Sidebar active='staff' />
          </div>
        </div>
        <div
          className='col order-2'
          id='main'>
          <div className='container'>
            <Menu />
            <div className='contents'>
              <div className='row justify-content-center'>
                <div className='col-12 col-lg-10 col-xl-12'>
                  <div className='header mt-md-3'>
                    <nav aria-label='breadcrumb mb-2'>
                      <ol className='breadcrumb'>
                        <li className='breadcrumb-item'>
                          <a href='/Overview'>Dashboard</a>
                        </li>
                        <li className='breadcrumb-item'>
                          <a href='/staff'>Corporate</a>
                        </li>
                        <li className='breadcrumb-item'>
                          <a href='/staff'>Staff</a>
                        </li>
                        <li
                          className='breadcrumb-item active'
                          aria-current='page'>
                          Add Staff
                        </li>
                      </ol>
                    </nav>
                  </div>
                  <div className='content'>
                    <div className='row mt-3'>
                      <div className='col-md-12'>
                        <div
                          className='card mt-2'
                          style={{
                            background: "#FFFFFF",
                            border: "1px solid #E3EBF6",
                            borderRadius: "10px",
                          }}>
                          <div className='px-4 pt-4'>
                            <div className='row'>
                              <div className='col-9'>
                                <h6 className='header-pretitle'>Overview</h6>
                                <h1 className='header-title'>Add Staff</h1>
                              </div>
                              <div className='col-3'>
                                <input
                                  type={"file"}
                                  id={"csvFileInput"}
                                  accept={".csv"}
                                  name='file'
                                  style={{ display: "none" }}
                                />
                                <button
                                  className='btn btn-outline-primary btn-sm'
                                  onClick={(e) => {
                                    handleSubmitCSV(e);
                                  }}
                                  id='upload'>
                                  Upload CSV
                                </button>
                              </div>
                            </div>
                            <hr />
                            <div className='row'>
                              <div className='col-md-12'>
                                <Container>
                                  <Form onSubmit={handleSubmit}>
                                    {message}
                                    {inputFields.map((data, index) => {
                                      const {
                                        firstName,
                                        lastName,
                                        phone,
                                        role,
                                        email,
                                        subscriptionGroup,
                                      } = data;
                                      return (
                                        <div
                                          className='row'
                                          key={index}>
                                          <Row>
                                            <Col
                                              xs={12}
                                              md={6}>
                                              <Form.Group className='mb-3'>
                                                <Form.Label>
                                                  First Name
                                                </Form.Label>
                                                <Form.Control
                                                  type='text'
                                                  name='firstName'
                                                  autoFocus
                                                  defaultValue={firstName}
                                                  onChange={(event) =>
                                                    handleChange(index, event)
                                                  }
                                                  id='firstName'
                                                  required
                                                  placeholder='Enter First Name'
                                                />
                                              </Form.Group>
                                            </Col>
                                            <Col
                                              xs={12}
                                              md={6}>
                                              <Form.Group className='mb-3'>
                                                <Form.Label>
                                                  Last Name
                                                </Form.Label>
                                                <Form.Control
                                                  type='text'
                                                  placeholder='Enter Last Name'
                                                  name='lastName'
                                                  id='lastName'
                                                  autoFocus
                                                  defaultValue={lastName}
                                                  onChange={(event) =>
                                                    handleChange(index, event)
                                                  }
                                                  required
                                                />
                                              </Form.Group>
                                            </Col>
                                          </Row>
                                          <Row>
                                            <Col
                                              xs={12}
                                              md={6}>
                                              <Form.Group className='mb-3'>
                                                <Form.Label>
                                                  Phone Number
                                                </Form.Label>
                                                <Form.Control
                                                  type='number'
                                                  name='phone'
                                                  autoFocus
                                                  id='phone'
                                                  defaultValue={phone}
                                                  onChange={(event) =>
                                                    handleChange(index, event)
                                                  }
                                                  placeholder='Enter phone Number'
                                                />
                                              </Form.Group>
                                            </Col>
                                            <Col
                                              xs={12}
                                              md={6}>
                                              <Form.Group className='mb-3'>
                                                <Form.Label>Email</Form.Label>
                                                <Form.Control
                                                  type='email'
                                                  autoFocus
                                                  id='email'
                                                  name='email'
                                                  defaultValue={email}
                                                  onChange={(event) =>
                                                    handleChange(index, event)
                                                  }
                                                  required
                                                  placeholder='Enter Email Address'
                                                />
                                              </Form.Group>
                                            </Col>
                                          </Row>
                                          <Row>
                                            <Col
                                              xs={12}
                                              md={6}>
                                              <Form.Group className='mb-3'>
                                                <Form.Label>Role</Form.Label>
                                                <Form.Select
                                                  aria-label='select Status'
                                                  id='role'
                                                  name='role'
                                                  required
                                                  defaultValue={role}
                                                  onChange={(event) =>
                                                    handleChange(index, event)
                                                  }>
                                                  <option select platform>Select Role</option>
                                                  <option value='admin'>
                                                    Cooperate Admin
                                                  </option>
                                                  <option value='user'>
                                                    Cooperate User
                                                  </option>
                                                </Form.Select>
                                              </Form.Group>
                                            </Col>
                                            <Col
                                              xs={12}
                                              md={6}>
                                              <Form.Group className='mb-3'>
                                                <Form.Label>
                                                  Subscription Group
                                                </Form.Label>
                                                <Form.Select
                                                  aria-label='select subscription'
                                                  name='subscriptionGroup'
                                                  className='form-select'
                                                  id='subscriptionGroup'
                                                  onChange={(event) =>
                                                    handleChange(index, event)
                                                  }
                                                  required
                                                  defaultValue={
                                                    subscriptionGroup
                                                  }>
                                                  <option select platform>Select Subscription Group</option>
                                                  {companySubscriptionGroups.map(
                                                    (
                                                      companySubscriptionGroup,
                                                    ) => (
                                                      <option
                                                        value={
                                                          companySubscriptionGroup.id
                                                        }
                                                        key={
                                                          companySubscriptionGroup.id
                                                        }>
                                                        {
                                                          companySubscriptionGroup.name
                                                        }
                                                      </option>
                                                    ),
                                                  )}
                                                </Form.Select>
                                              </Form.Group>
                                            </Col>
                                          </Row>
                                          <Row className='addrow my-4'>
                                            <Col
                                              xs={12}
                                              md={6}
                                              className='addcol d-flex justify-content-start'>
                                              <button
                                                type='button'
                                                className='btn btn-link disabled'
                                                onClick={addInputField}>
                                                + Add more{" "}
                                              </button>
                                            </Col>
                                            <Col
                                              xs={12}
                                              md={6}
                                              className='col-auto d-flex justify-content-end'>
                                              <Button
                                                className='btn btn-primary lift'
                                                type='submit'>
                                                Submit
                                              </Button>
                                              <ToastContainer />
                                            </Col>
                                          </Row>
                                          <Row>
                                            <Col
                                              xs={1}
                                              md={1}
                                              className='col-auto'>
                                              {inputFields.length !== 1 ? (
                                                <button
                                                  type='button'
                                                  className='btn btn-outline-secondary  my-4'
                                                  onClick={removeInputFields}>
                                                  x
                                                </button>
                                              ) : (
                                                ""
                                              )}
                                            </Col>
                                          </Row>
                                        </div>
                                      );
                                    })}
                                  </Form>
                                </Container>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default AddStaff;
